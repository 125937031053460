import React, { createContext, useEffect, useState } from "react";
import {
  getCustomerAddresses,
  findCustomerByAuthId,
  createNewCustomer,
  createNewAddress,
  deleteCustomerAddress,
  updateCustomerDetails,
  createOrder,
  getCustomerTotalNumberOfOrders,
  findCustomerByEmail,
  updateCustomerAfterPurchase,
  batchUpdateDefaultAddress,
  updateDefaultPaymentMethod,
  updateAddress,
  getReferral,
  setReferralCreditUsed,
} from "../../repository/customers";
import { v4 as uuidv4 } from "uuid";
import ruralPostcode from "../../helpers/ruralPostcode.json";
import { updateAutoshipRemovedPaymentMethod } from "../../repository/subscriptions";
import { getSMSConsent } from "../../helpers/dataTrackingManagement";
import { createNewError } from "../../repository/errors";

import * as Sentry from "@sentry/gatsby";
import { getWasExpressCustomer, setWasExpressCustomer } from "../../helpers/localStorage";
import axios from "axios";


const defaultState = {
  isProcessingCreateCustomer: false,
  customer: null,
  paymentMethods: [],
  wasExpressCustomer: getWasExpressCustomer(),
  checkoutExperience: "unknown",
  loyaltyTier: {
    loadTierProgressBar: true,
    tierPercentageAchieved: 1,
  },
};

export const CustomerContext = createContext(defaultState);

export const CustomerContextProvider = ({ children }) => {
  const [state, setState] = useState(defaultState);

  useEffect(() => {
    if (state?.customer?.ordersCount >= 0) {
      setWasExpressCustomer(state?.customer?.ordersCount > 0)
    }
  }, [state?.customer?.ordersCount])

  const findCustomerDetailsByAuthId = async (
    authId,
    email = null,
    shouldCreateCustomer = false,
  ) => {
    try {
      const customer = await findCustomerByAuthId(authId);
      if (customer) {
        await getCustomerDetails(customer, customer.stripeId);
      } else if (email && shouldCreateCustomer) {
        const existingCustomer = await findCustomerByEmailAddress(
          email.toLowerCase(),
        );
        if (!existingCustomer && !state.isProcessingCreateCustomer) {
          await createCustomerInDatabase(
            { firstName: "", lastName: "", email: email },
            authId,
          );
        }
      }
    }
    catch (err) {
      console.error(err, "Not able to find details.", authId);
    }
  };

  const findCustomerDetailsByEmail = async (email) => {
    try {
      const customer = await findCustomerByEmail(email);
      if (customer) {
        await getCustomerDetails(customer, customer.stripeId);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const retrieveCustomerTotalNumberOfOrders = async (customerId) => {
    const ordersCount = await getCustomerTotalNumberOfOrders(customerId);
    let checkoutExperience = "standard"
    if (ordersCount > 0) {
      checkoutExperience = "express"
    }
    setState(prevState => ({ ...prevState, checkoutExperience }));
    return ordersCount;
  }

  const getCustomerDetails = async (customer, stripeId = null) => {
    Sentry.setUser({ email: customer?.email })

    // Wrap each async call in a function that handles its errors
    const handlePromise = (promise) =>
      promise.then(data => ({ success: true, data })).catch(error => ({ success: false, error }));

    const paymentMethodsPromise = handlePromise(getSavedPaymentMethodForCustomer(stripeId));
    const customerTagsPromise = handlePromise(getCustomerTags(customer.shopifyId));
    const addressesPromise = handlePromise(getCustomerAddresses(customer.id));
    const ordersCountPromise = handlePromise(retrieveCustomerTotalNumberOfOrders(customer.id));
    const smsConsentPromise = customer?.email && customer?.phoneNumber
      ? handlePromise(getSMSConsent(customer?.email, customer?.phoneNumber))
      : Promise.resolve({ success: true, data: false });
    const referralPromise = customer.referralCodeIds?.length > 0
      ? handlePromise(getReferral(customer.referralCodeIds[0]))
      : Promise.resolve({ success: true, data: null });

    // Wait for all of them to complete
    const [
      paymentMethodsResult,
      customerTagsResult,
      addressesResult,
      ordersCountResult,
      smsConsentResult,
      referralResult
    ] = await Promise.all([
      paymentMethodsPromise,
      customerTagsPromise,
      addressesPromise,
      ordersCountPromise,
      smsConsentPromise,
      referralPromise
    ]);

    // Handle each result, checking for success or failure
    let _customer = { ...customer };

    if (customerTagsResult.success) {
      _customer.loyaltyTier = customerTagsResult.data.loyaltyTier;
      _customer.preferredCourier = customerTagsResult.data.preferredCourier;
      _customer.eligibleLoyaltyPercentage = customerTagsResult.data.loyaltyTier?.discountPercent || 5;
    }
    _customer.addresses = addressesResult.success ? addressesResult.data : [];
    _customer.ordersCount = ordersCountResult.success ? ordersCountResult.data : 0;
    _customer.smsConsent = smsConsentResult.success ? smsConsentResult.data : false;
    _customer.referral = referralResult.success ? referralResult.data : null;

    if (_customer.dateOfBirth && typeof _customer.dateOfBirth === "object") {
      _customer.dateOfBirth = _customer.dateOfBirth.toDate();
    }
    _customer.customerCredit = getCustomerCredit(_customer);
    setState(prevState => ({
      ...prevState,
      customer: _customer,
      paymentMethods: paymentMethodsResult.success ? paymentMethodsResult.data : [],
    }));
  };

  const customerHasReferralCredit = (customer) => {
    return customer?.referral && customer?.referral?.codeUsed && customer?.referral?.creditStatus === "APPROVED" && !customer?.referral?.creditUsed
  }

  const getCustomerCredit = (customer) => {
    // For now the only medium to get credit is through referral
    let credit = [];
    try {
      if (customerHasReferralCredit(customer)) {
        credit.push({
          creditMessage: "Referral Credit",
          amount: customer.referral.creditAmount
        })
      }
    } catch (err) {
      console.error(err);
      return []
    }
    return credit;
  }

  const setCreditUsed = async (customer) => {
    if (customer && customerHasReferralCredit(customer)) {
      await setReferralCreditUsed(customer.referral.id);
      setState(prevState => ({
        ...prevState,
        customer: {
          ...prevState.customer,
          referral: {},
          customerCredit: []
        }
      }));
    }
  }

  const getCustomerTags = async (shopifyId) => {
    if (shopifyId) {
      const response = await fetch("/.netlify/functions/find-customer-tags", {
        method: "POST",
        body: JSON.stringify({
          shopify_id: shopifyId,
        }),
      });
      if (response.status === 200) {
        const { loyaltyTier, preferredCourier } = await response.json();
        const returnObj = { loyaltyTier, preferredCourier };
        return returnObj;
      }
    }

    const initialTier = {
      tier: "bronze",
      amountSpent: 0,
      code: "CCR100",
      discountPercent: 5,
    };
    const returnObj = {
      loyaltyTier: initialTier,
      preferredCourier: "FAST_AS_POSSIBLE",
    };
    return returnObj;
  };

  const updateDefaultAddress = async (customerId, addresses) => {
    try {
      const _addresses = await batchUpdateDefaultAddress(customerId, addresses);
      setState(prevState => ({
        ...prevState,
        customer: {
          ...prevState.customer,
          addresses: _addresses ? _addresses : []
        },
      }));
    } catch (err) {
      console.error(err);
    }
  };

  const updateCustomerAddress = async (customerId, address) => {
    try {
      const _address = await updateAddress(customerId, address);
      let _customer = { ...state.customer };
      _customer.addresses =
        setState(prevState => {
          let addresses = prevState.customer.addresses.map((a) =>
            a.id === _address.id ? _address : a
          );
          return {
            ...prevState,
            customer: {
              ...prevState.customer,
              addresses
            }
          }
        });
    } catch (err) {
      console.error(err);
    }
  };

  const updateDefaultPayment = async (customerId, paymentId) => {
    try {
      await updateDefaultPaymentMethod(customerId, paymentId);
      setState(prevState => ({
        ...state,
        customer: {
          ...prevState.customer,
          defaultPaymentMethod: paymentId
        },
      }));
    } catch (err) {
      console.error(err);
    }
  };
  const refreshPaymentMethods = async (stripeId) => {
    try {
      const paymentMethods = await getSavedPaymentMethodForCustomer(stripeId);
      setState(prevState => ({
        ...prevState,
        paymentMethods: paymentMethods,
      }));
    } catch (err) {
      console.error(err);
    }
  };

  const getSavedPaymentMethodForCustomer = async (stripeId) => {
    try {
      if (stripeId) {
        const res = await fetch(
          "/.netlify/functions/list-customer-payment-method",
          {
            method: "POST",
            body: JSON.stringify({ id: stripeId }),
          },
        );
        const { paymentMethods } = await res.json();
        if (paymentMethods && paymentMethods.data) {
          return paymentMethods.data;
        } else {
          return [];
        }
      }
    } catch (err) {
      Sentry.captureException(err)
      return [];
    }
  };

  const logoutCustomer = () => {
    setState(defaultState);
  };

  const saveCustomerAddress = async (customerId, address) => {
    try {
      if (address) {
        //Check if address is existent
        if (state.customer?.addresses?.length > 0) {
          const existingAddress = state.customer.addresses.find(
            (a) =>
              a.note === address.note &&
              a.firstName === address.firstName &&
              a.addressLine1 === address.addressLine1 &&
              a.addressLine2 === address.addressLine2 &&
              a.city === address.city &&
              a.postCode === address.postCode &&
              a.isVerified === address.isVerified,
          );
          if (existingAddress) {
            return;
          }
        }

        createNewAddress(customerId, address);
        if (state.customer) {
          let _addresses = [...state.customer.addresses];
          if (_addresses) {
            _addresses = [address, ..._addresses];
          } else {
            _addresses = [];
            _addresses.push(address);
          }
          setState(prevState => ({
            ...prevState,
            customer: {
              ...prevState.customer,
              addresses: _addresses
            }
          }));
        }
      }
    } catch (err) {
      console.error(err);
    }
  };

  const notifyErrorQueueOrderForProcessing = async (orderId, customerId, error) => {
    await fetch("/.netlify/functions/send-email", {
      method: "POST",
      body: JSON.stringify({
        to: "dev@magnesium.nz",
        subject: "Urgent! Website error when adding to WebsiteQueue",
        text: "Urgent",
        html: `
          <p>You may need to add this order to the WebsiteQueue in the database</p>
          <p><strong>OrderId: </strong> ${JSON.stringify(orderId)}</p>
          <p><strong>CustomerId: </strong> ${JSON.stringify(customerId)}</p>
          <p><strong>Error Message: </strong> ${JSON.stringify(error?.message)}</p>
          <p><strong>Stack: </strong> ${JSON.stringify(error?.stack)}</p>
          `,
      }),
    });
  }

  const queueOrderForProcessing = async (order) => {
    try {
      await axios.post(`${process.env.GATSBY_API_URL}/Webhook/QueueOrderForProcessing/${order.id}/${order.customerId}`);
    } catch (err) {
      await notifyErrorQueueOrderForProcessing(order.id, order.customerId, err);
    };
  };

  const notifyErrorSaveOrderDetails = async (
    message,
    error,
    email,
    customer,
    orderDetails,
    address,
    lineItems,
    paymentResult,
    paymentMethod,
  ) => {
    await fetch("/.netlify/functions/send-email", {
      method: "POST",
      body: JSON.stringify({
        to: "dev@magnesium.nz",
        subject: "Urgent! Website error when saving order details",
        text: "Urgent",
        html: `<p><strong>Message: </strong> ${JSON.stringify(message)}</p>
          <p><strong>Actual Error Message: </strong> ${JSON.stringify(error?.message)}</p>
          <p><strong>Stack: </strong> ${JSON.stringify(error?.stack)}</p>
          <p><strong>email: </strong> ${JSON.stringify(email)}</p>
          <p><strong>customer: </strong> ${JSON.stringify(customer)}</p>
          <p><strong>orderDetails: </strong> ${JSON.stringify(orderDetails)}</p>
          <p><strong>lineItems: </strong> ${JSON.stringify(lineItems)}</p>
          <p><strong>address: </strong> ${JSON.stringify(address)}</p>
          <p><strong>paymentResult: </strong> ${JSON.stringify(
          paymentResult,
        )}</p>
          <p><strong>paymentMethod: </strong> ${JSON.stringify(
          paymentMethod,
        )}</p>
          `,
      }),
    });
  };

  const saveOrderDetails = async (
    email,
    customer,
    orderDetails,
    address,
    lineItems,
    paymentResult,
    paymentMethod,
    signup,
    login,
    findCustomerDetailsByAuthId,
    tags,
  ) => {
    try {
      if (!customer?.id) {
        let stripe_customer = null;
        if (orderDetails.stripeCustomerId) {
          stripe_customer = orderDetails.stripeCustomerId;
          await updateCustomerStripe({
            firstName: address.firstName,
            lastName: address.lastName,
            id: orderDetails.stripeCustomerId,
          });
        } else {
          const newStripeCustomer = await createCustomerStripe({
            firstName: address.firstName,
            lastName: address.lastName,
            email: email,
          });
          if (newStripeCustomer) {
            stripe_customer = newStripeCustomer.id;
          }
        }

        let newCustomer = {
          firstName: address.firstName || "",
          lastName: address.lastName || "",
          fullName:
            address.firstName?.trim() + " " + address.lastName?.trim(),
          email: email || "",
          phoneNumber: address.phoneNumber || "",
          isPhoneNumberVerified: false,
          createdAt: new Date(),
          stripeId: stripe_customer || "",
          shopifyId: orderDetails?.shopifyCustomerId || "",
        };
        customer = await createNewCustomer(newCustomer);
        customer.addresses = [];
        setState(prevState => ({ ...prevState, customer: customer }));
        await saveCustomerAddress(customer.id, address);
        const order = await createOrder(
          orderDetails,
          customer,
          lineItems,
          paymentResult,
          paymentMethod,
          signup,
          login,
          findCustomerDetailsByAuthId,
          tags,
        );
        await queueOrderForProcessing(order);
      } else {
        await saveCustomerAddress(customer.id, address);
        const order = await createOrder(
          orderDetails,
          customer,
          lineItems,
          paymentResult,
          paymentMethod,
          signup,
          login,
          findCustomerDetailsByAuthId,
          tags,
        );
        await queueOrderForProcessing(order);
      }
    } catch (error) {
      Sentry.withScope(scope => {
        scope.setLevel("fatal");
        Sentry.captureMessage("Error when processing order");
        Sentry.captureException(error);
      });
      await notifyErrorSaveOrderDetails(
        "error when processing order",
        error,
        email,
        customer,
        orderDetails,
        address,
        lineItems,
        paymentResult,
        paymentMethod,
      );
    }
  };

  const removeCustomerAddress = async (addressId) => {
    try {
      let _addresses = [...state?.customer?.addresses];
      _addresses = _addresses.filter((a) => a.id !== addressId);
      if (addressId) {
        deleteCustomerAddress(state.customer.id, addressId);
        setState(prevState => ({
          ...prevState,
          customer: {
            ...prevState.customer,
            addresses: _addresses
          }
        }));
      }
    } catch (err) {
      console.error(err);
    }
  };

  // Add error capturing to this function
  const createCustomerInDatabase = async (
    identityUser,
    auth_id,
    address = null,
  ) => {
    const stripe_customer = await createCustomerStripe(identityUser);
    let { customer_id, firstName, lastName } =
      await createShopifyCustomer(identityUser);
    if (identityUser.firstName && identityUser.lastName) {
      firstName = identityUser.firstName;
      lastName = identityUser.lastName;
    }
    let customer = {
      firstName: firstName || "",
      lastName: lastName || "",
      fullName: firstName?.trim() + " " + lastName?.trim(),
      email: identityUser.email || "",
      phoneNumber: identityUser.phoneNumber || "",
      isPhoneNumberVerified: false,
      createdAt: new Date(),
      authId: auth_id,
      stripeId: stripe_customer?.id || "",
      shopifyId: customer_id || "",
    };
    customer = await createNewCustomer(customer);
    await getCustomerDetails(customer, stripe_customer?.id);
    if (address && customer) {
      saveCustomerAddress(customer.id, address);
    }
  };

  const createDbCustomerAfterPurchase = async (customer, auth_id) => {
    if (customer) {
      updateCustomerAfterPurchase(customer, auth_id);
    }
  };

  const createCustomerStripe = async (identityUser) => {
    const response = await fetch("/.netlify/functions/create-stripe-customer", {
      method: "POST",
      body: JSON.stringify({
        name:
          identityUser.firstName?.trim() + " " + identityUser.lastName?.trim(),
        email: identityUser.email?.trim(),
      }),
    });
    const { customer } = await response.json();
    return customer;
  };

  const createShopifyCustomer = async (identityUser) => {
    const response = await fetch(
      "/.netlify/functions/create-shopify-customer",
      {
        method: "POST",
        body: JSON.stringify({
          identityUser: identityUser,
        }),
      },
    );
    const shopifyCustomer = await response.json();
    return shopifyCustomer;
  };

  const fetchCustomerOrderFromShopify = async () => {
    const response = await fetch(
      "/.netlify/functions/fetch-shopify-customer-orders",
      {
        method: "POST",
        body: JSON.stringify({
          email: state.customer.email,
        }),
      },
    );
    const { orders } = await response.json();
    return orders;
  };

  const updateAccountDetails = async (newSettings) => {
    if (newSettings && state.customer) {
      let _customer = { ...state.customer };
      _customer.firstName = newSettings.firstName;
      _customer.lastName = newSettings.lastName;
      _customer.email = newSettings.email;
      if (_customer.phoneNumber !== newSettings.phoneNumber) {
        _customer.isPhoneNumberVerified = false;
      }
      if (newSettings.phoneNumber && newSettings.phoneNumber.length > 4) {
        if (!newSettings.phoneNumber.startsWith("+64")) {
          if (newSettings.phoneNumber.startsWith("0")) {
            _customer.phoneNumber =
              "+64" + newSettings.phoneNumber.substring(1);
          } else {
            _customer.phoneNumber = "+64" + newSettings.phoneNumber;
          }
        } else {
          _customer.phoneNumber = newSettings.phoneNumber;
        }
      }

      _customer.fullName = newSettings.firstName + " " + newSettings.lastName;
      _customer.dateOfBirth = newSettings.dateOfBirth;
      updateCustomerDetails(_customer);
      setState(prevState => ({ ...prevState, customer: _customer }));
    }
  };

  const updateTierProgressBar = async (
    shouldLoadProgressBar,
    percentageAchieved,
  ) => {
    setState(prevState => ({
      ...prevState,
      loyaltyTier: {
        ...prevState.loyaltyTier,
        loadTierProgressBar: shouldLoadProgressBar,
        tierPercentageAchieved: percentageAchieved,
      },
    }));
  };

  const removePaymentMethod = async (paymentMethodId) => {
    try {
      if (paymentMethodId) {
        fetch("/.netlify/functions/remove-saved-payment-method", {
          method: "POST",
          body: JSON.stringify({ payment_method_id: paymentMethodId }),
        })
          .then(async (response) => {
            const { paymentMethod } = await response.json();
            if (paymentMethod) {
              const _paymentMethods = state.paymentMethods.filter(
                (pm) => pm.id !== paymentMethod.id,
              );
              const _customer = { ...state.customer };
              const isDefaultPayment =
                _customer.defaultPaymentMethod === paymentMethod.id;
              if (isDefaultPayment) {
                _customer.defaultPaymentMethod = null;
                await updateAutoshipRemovedPaymentMethod(
                  _customer.id,
                  paymentMethodId,
                  null,
                );
                if (_paymentMethods?.length) {
                  _customer.defaultPaymentMethod = _paymentMethods[0].id;
                  await updateDefaultPaymentMethod(
                    _customer.id,
                    _customer.defaultPaymentMethod,
                  );
                } else {
                  await updateDefaultPaymentMethod(
                    _customer.id,
                    _customer.defaultPaymentMethod,
                  );
                }
                setState(prevState => ({
                  ...prevState,
                  _customer: _customer,
                }));
              } else {
                await updateAutoshipRemovedPaymentMethod(
                  _customer.id,
                  paymentMethodId,
                  _customer.defaultPaymentMethod,
                );
              }
              setState(prevState => ({
                ...prevState,
                paymentMethods: _paymentMethods,
              }));
            }
            return "Payment Method Removed";
          })
          .catch((err) => {
            throw new Error("Unable to Remove Payment Method");
          });
      }
    } catch (err) {
      throw new Error("Unable to Remove Payment Method");
    }
  };

  const verifyShopifyCustomerLogin = async (email, password) => {
    const response = await fetch(
      "/.netlify/functions/verify-shopify-customer-login",
      {
        method: "POST",
        body: JSON.stringify({
          email,
          password,
        }),
      },
    );
    const { accessToken } = await response.json();
    return accessToken;
  };
  const checkIfHasShopifyAccount = async (email) => {
    const response = await fetch(
      "/.netlify/functions/check-existing-shopify-account",
      {
        method: "POST",
        body: JSON.stringify({
          email,
        }),
      },
    );
    const { hasShopifyAccount } = await response.json();
    return hasShopifyAccount;
  };
  const findShopifyCustomerByAccessToken = async (accessToken) => {
    const response = await fetch(
      "/.netlify/functions/find-shopify-customer-by-access-token",
      {
        method: "POST",
        body: JSON.stringify({
          accessToken,
        }),
      },
    );
    const { customer } = await response.json();
    return customer;
  };

  const updateCustomerStripe = async (customerDetails) => {
    const response = await fetch("/.netlify/functions/update-stripe-customer", {
      method: "POST",
      body: JSON.stringify({
        name:
          customerDetails?.firstName?.trim() +
          " " +
          customerDetails?.lastName?.trim(),
        id: customerDetails?.id,
      }),
    });
    const { customer } = await response.json();
    return customer;
  };

  const setIsProcessingCreateCustomer = async (value) => {
    setState(prevState => ({
      ...prevState,
      isProcessingCreateCustomer: value,
    }));
  };

  const findCustomerByEmailAddress = async (email) => {
    try {
      const customer = await findCustomerByEmail(email);
      return customer;
    } catch (err) {
      await createNewError({
        createdAt: new Date(),
        payload: JSON.stringify({
          errorName: err.name,
          errorMessage: err.message,
        }),
        source: "findCustomerByEmailAddress",
        message: "Error in find customer by email"
      })
      console.error(err);
    }
  };

  const migrateShopifyCustomer = async (shopifyCustomer) => {
    if (shopifyCustomer && shopifyCustomer.email) {
      const customerDb = await findCustomerByEmail(
        shopifyCustomer.email.toLowerCase(),
      );
      if (customerDb) {
        await findCustomerDetailsByEmail(customerDb.email);
      } else {
        const customerPhoneNumber = shopifyCustomer.phone
          ? shopifyCustomer.phone.replace(/\s/g, "")
          : "";
        const signupForm = {
          firstName: shopifyCustomer.firstName,
          lastName: shopifyCustomer.lastName,
          email: shopifyCustomer.email.toLowerCase(),
          phoneNumber: customerPhoneNumber,
        };
        let defaultAddress = null;

        if (shopifyCustomer.defaultAddress) {
          const phoneNumber = shopifyCustomer.defaultAddress.phone
            ? shopifyCustomer.defaultAddress.phone.replace(/\s/g, "")
            : "";
          defaultAddress = {
            id: uuidv4(),
            firstName: shopifyCustomer.defaultAddress.firstName || "",
            lastName: shopifyCustomer.defaultAddress.lastName || "",
            addressLine1: shopifyCustomer.defaultAddress.address1 || "",
            addressLine2: shopifyCustomer.defaultAddress.address2 || "",
            city: shopifyCustomer.defaultAddress.city || "",
            postCode: shopifyCustomer.defaultAddress.zip || "",
            companyName: shopifyCustomer.defaultAddress.company || "",
            note: "",
            label: "Home",
            phoneNumber: phoneNumber,
            dpid: "",
            isPostal: "N",
            ruralDelivery: "",
            isVerified: false,
            isDefaultAddress: true,
            isMigratedData: true,
          };

          if (defaultAddress && defaultAddress.postCode) {
            defaultAddress = await checkIfRuralPostcode(defaultAddress);
          }

          await createCustomerInDatabase(signupForm, "", defaultAddress);
        }
      }
    }
  };

  const checkIfRuralPostcode = async (address) => {
    if (address?.postCode?.length === 4) {
      const ruralPostcodes = ruralPostcode;
      const postCode = await ruralPostcodes.find(
        (p) => p.Postcode === address?.postCode,
      );
      if (postCode) {
        address.ruralDelivery = postCode.SuburbName;
        address.isVerified = true;
        return address;
      } else {
        address.ruralDelivery = "";
        address.isVerified = true;
        return address;
      }
    }
  };

  return (
    <CustomerContext.Provider
      value={{
        customer: state.customer,
        wasExpressCustomer: state.wasExpressCustomer,
        checkoutExperience: state.checkoutExperience,
        paymentMethods: state.paymentMethods,
        loyaltyTier: state.loyaltyTier,
        isProcessingCreateCustomer: state.isProcessingCreateCustomer,
        setIsProcessingCreateCustomer,
        getCustomerDetails,
        logoutCustomer,
        getSavedPaymentMethodForCustomer,
        findCustomerDetailsByAuthId,
        findCustomerDetailsByEmail,
        fetchCustomerOrderFromShopify,
        createCustomerInDatabase,
        saveCustomerAddress,
        removeCustomerAddress,
        updateAccountDetails,
        saveOrderDetails,
        createShopifyCustomer,
        createDbCustomerAfterPurchase,
        verifyShopifyCustomerLogin,
        findShopifyCustomerByAccessToken,
        updateTierProgressBar,
        removePaymentMethod,
        refreshPaymentMethods,
        updateDefaultAddress,
        updateDefaultPayment,
        updateCustomerAddress,
        checkIfHasShopifyAccount,
        createCustomerStripe,
        findCustomerByEmailAddress,
        migrateShopifyCustomer,
        setCreditUsed,
      }}
    >
      {children}
    </CustomerContext.Provider>
  );
};

export default CustomerContext;
