import React from "react";
import "react-loading-skeleton/dist/skeleton.css";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { NotificationProvider } from "./src/context/AddItemNotificationProvider";
import { StoreProvider } from "./src/context/store/StoreContext";
import { AuthContextProvider } from "./src/context/auth/AuthContext";
import { CustomerContextProvider } from "./src/context/customer/CustomerContext";
import { MiniCartContextProvider } from "./src/context/drawer/MiniCartContext";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { CheckoutContextProvider } from "./src/context/checkout/CheckoutContext";
import { ShopifyContextProvider } from "./src/context/shopify/ShopifyContext";
import { WindowContextProvider } from "./src/context/window/WindowContext";

const stripePromise = loadStripe(process.env.GATSBY_STRIPE_PUBLIC_KEY);

const muiTheme = createTheme({
  palette: {
    primary: {
      main: "#862121",
    },
  },
  typography: {
    fontFamily: [
      "Inter",
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'Inter';
        }
      `,
    },
  },
});

export const wrapRootElement = ({ element }) => (
  <Elements stripe={stripePromise}>
    <ThemeProvider theme={muiTheme}>
      <StoreProvider>
        <AuthContextProvider>
          <CustomerContextProvider>
            <CheckoutContextProvider>
              <ShopifyContextProvider>
                <NotificationProvider>
                  <MiniCartContextProvider>
                    <WindowContextProvider>{element}</WindowContextProvider>
                  </MiniCartContextProvider>
                </NotificationProvider>
              </ShopifyContextProvider>
            </CheckoutContextProvider>
          </CustomerContextProvider>
        </AuthContextProvider>
      </StoreProvider>
    </ThemeProvider>
  </Elements>
);
