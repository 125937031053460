const groq = require("groq");

const slugQuery = groq`
  'slug': content.main.slug.current
`;
const ImageQuery = groq`
  'imageAsset': asset, 
  'imageSrc': asset->url, 
  'width': asset->metadata.dimensions.width,
  'height': asset->metadata.dimensions.height,
`;

const ImageQueryWithBlur = groq`
  'imageAsset': asset->{...}, 
  'imageSrc': asset->url, 
  'width': asset->metadata.dimensions.width,
  'height': asset->metadata.dimensions.height,
`;

const SEOQuery = groq`
  _type,
  metaKeywords,
  metaDescription,
  metaTitle,
  canonicalUrl,
  openGraphDescription,
  'openImage': openImage.asset->url,
  openTitle,
  twitterTitle,
  twitterDescription,
  'twitterImage': twitterImage.asset->url
`;
const VariantQuery = groq`
  'title': content.main.title,
  'productTitle': content.shopify.title,
  'productId':content.shopify.productId,
  'price': content.shopify.price,
  'compareAtPrice': content.shopify.compareAtPrice,
  'availableForSale': content.shopify.availableForSale,
  'sku': content.shopify.sku,
  'shopifyId': content.shopify.variantId,
  'numberOfCapsules': content.main.numberOfCapsules,
  'mainImage': content.main.mainImage.asset,
  'mainImageSrc': content.main.mainImage.asset->url,
`;

const globalSettingsQuery = groq`*[_type == "siteGlobal"][0] {
  ...,
  'defaultMeta': content.meta {
    ${SEOQuery}
  },
  'social': content.social.socialLinks
}`;

const ProductQuery = groq`
  ${slugQuery},
  'mainImage': content.main.mainImage.asset,
  'mainImageSrc': content.main.mainImage.asset->url,
  'title': content.main.title,
  'subtitle': content.main.subtitle,
  'isOutOfStock': content.main.isOutOfStock,
  'backInStockDate': content.main.backInStockDate,
  'backInStockLabel': content.main.backInStockLabel,
  'shopifyId': content.shopify.productId,
  'variants': content.shopify.variants[]-> {
    ${VariantQuery}
  },
  'modules': content.main.modules[] {
    ...,
    
  },
  'meta': content.meta {
    ${SEOQuery}
  },
`;

const PostQuery = groq`
  ${slugQuery},
  'headerImage': content.main.headerImage.asset,
  'headerImageSrc': content.main.headerImage.asset->url,
  'thumbnailImage': content.main.thumbnailImage.asset,
  'thumbnailImageSrc': content.main.thumbnailImage.asset->url,
  'body': content.main.body[]
  {
    ...,
    buttonLink {
      title,
      link-> {'slug': content.main.slug.current}
    }
  },
  'excerpt': content.main.excerpt[],
  'title': content.main.title,
  'publishedAt': content.main.publishedAt,
  'meta': content.meta {
    ${SEOQuery}
  },
  ...,
`;

const moduleQuery = groq`
  _type == 'nestedPages' => {
    ...,
    page[] {
      ...,
      linkedPage->
    }
  },

  _type == 'productGrid' => {
    ...,
    products[]-> {
      ${ProductQuery}
    }
  },
  _type == 'shopPage' => {
    ...,
    collections[]-> {
      ...,
      products[]-> {
        ${ProductQuery}
      },
      conditionalProducts[] {
        ...,
        product -> {
          ${ProductQuery}
        }
      }
    }
  },
  _type == 'productCollection' => {
    ...,
    collection-> {
      ...,
      products[]-> {
      ${ProductQuery}
    }
    }
  },

  _type == 'homeCompostableLandingPage' => {
    ...,
    homeCompostableHero {
      ...,
      img {
        ${ImageQueryWithBlur}
      }
    },
    homeCompostableOverlaidText {
      ...,
      img {
        ${ImageQuery}
      }
    },
    productInfoSlider {
      ...,
      products[] {
        ...,
        'image' : productImage {
          ${ImageQueryWithBlur}
        },
        'data' : product->{
          ${ProductQuery}
        }
      }
    },
    homeCompostableInnovativeTechnology {
      ...,
      img {
        ${ImageQueryWithBlur}
      },
      tabletImage {
        ${ImageQueryWithBlur}
      },
      mobileImage {
        ${ImageQueryWithBlur}
      },
    },
    homeCompostableCompletelyCompostable {
      ...,
      img {
        ${ImageQuery}
      },
      mobileImg {
        ${ImageQuery}
      },
      
    },
    homeCompostableZeroEffortZeroWasteItem {
      ...,
      images[] {
        ...,
        img {
          ${ImageQuery}
        }
      }
    },
    homeCompostableGoodTaste {
      ...,
      leftImage {
        ${ImageQueryWithBlur}
      },
      topRightImage {
        ${ImageQueryWithBlur}
      },
      bottomRightImage {
        ${ImageQueryWithBlur}
      },
    },
    homeCompostableGoodPrice {
      ...,
      img {
        ${ImageQueryWithBlur}
      }
    },
    homeCompostableFooter {
      ...,
    }
  },
  
  _type == 'accordion' => {
    ...,
    accordionItem[]-> {
      ...,
    }
  },
  _type == 'valueProposition' => {
    ...,
    valuePropositionItems[] {
      ...,
      internalLink {
        title,
        link-> {'slug': content.main.slug.current}
      }
    }
  },
  _type == 'gallery' => {
    ...,
    imagesModule[] {
      ...,
      image {
        ...,
        ${ImageQuery}
      }
    }
   },
   _type == 'imageModule' => {
    ...,
    image {
      ...,
      ${ImageQuery}
    }
   },
   _type == 'textBlockImage' => {
    ...,
    imagesModule[] {
      ...,
      image {
        ...,
        ${ImageQuery}
      }
    }
   },
   _type == 'textBlockImage2Columns' => {
    ...,
    imagesModule[] {
      ...,
      image {
        ...,
        ${ImageQuery}
      }
    }
   },
   _type == 'imageSlider' => {
    ...,
    leftImage {
      ${ImageQuery}
    },
    rightImage {
      ${ImageQuery}
    },
    extraImage {
      ${ImageQuery}
    },
    mobileImage {
      ${ImageQuery}
    }
   },
   _type == 'banner' => {
    ...,
    image {
      ${ImageQuery}
    },
    mobileImage {
      ${ImageQuery}
    },
   },
   _type == 'dynamicBanner' => {
    ...,
    image {
      ${ImageQueryWithBlur}
    },
   },
   _type == 'looxReviewsByProduct' => {
    ...,
    product->
   },
   _type == 'tasterPackFeature' => {
    ...,
    tasterPackProduct -> {
      ${ProductQuery}
    },
    imageSlideShow[] {
      ...,
      mainImage {
        ${ImageQuery}
      },
      thumbnailImage {
        ${ImageQuery}
      }
    },
    capsulesBreakout[] {
      ...,
      productImage {
        ${ImageQuery}
      },
      product -> {
        ${ProductQuery}
      },
    },
   },
   _type == 'stepGallery' => {
    ...,
    steps[] {
      ...,
      img {
        ${ImageQuery}
      }
    }
   },
`;

const pagesQuery = groq`*[_type == "page"]{
  ${slugQuery},
  'title': content.main.title,
  ...,
  'meta': content.meta {
    ${SEOQuery}
  },
  'hasStickyHeader': content.main.modules[_type == 'stickyHeader']{_type},
  'isLandingPage': content.main.isLandingPage,
  'headerBehaviour' : content.main.headerBehaviour,
  'smoothScrolling': content.main.smoothScrolling,
  'modules': content.main.modules[] {
    ...,
    ${moduleQuery}
  },
}
`;

const salesPromoQuery = groq`*[_type == "sale" && isActive == true] | order(fromDate asc)[0]{
  ...,
  mobileImage {
    ${ImageQueryWithBlur}
  },
  desktopImage {
    ${ImageQueryWithBlur}
  },
  internalLink {
    title,
    link-> {'slug': content.main.slug.current, _type}
  },
  bannerInternalLink {
    title,
    link-> {'slug': content.main.slug.current, _type}
  },
}
`;

const productsQuery = groq`*[_type == "product" && content.shopify.deleted != true] | order(title asc){
  ${ProductQuery}
}`;

const postsQuery = groq`*[_type == "post"]| order(publishedAt asc){
  ${PostQuery}
}`;

const shopifyProductsQuery = `{
  allShopifyProduct(sort: {title: ASC}) {
    edges {
      node {
        title
        images {
          originalSrc
          altText
          id
          shopifyId
          gatsbyImageData
        }
        shopifyId
        handle
        description
        priceRangeV2 {
          maxVariantPrice {
            amount
          }
          minVariantPrice {
            amount
          }
        }
        variants {
          availableForSale
          shopifyId
          title
          price
          selectedOptions {
            name
            value
          }
          product {
            title
          }
          image {
            id
            altText
            originalSrc
            gatsbyImageData
          }
        }
        status
      }
    }
  }
}`;

module.exports.getShippingRates = () => {
  return groq`*[_type == "shipping"]{
    ...
  }`;
};

module.exports.getAutoshipDiscount = () => {
  return groq`*[_type == "autoship"]{
    autoshipDiscountActiveFor,
    autoshipDiscountRate,
  }`;
};

module.exports.getUrlTagsMapping = () => {
  return groq`*[_type == "urlTagging"]{
    tagMapping,
  }`;
};

module.exports.getAutoshipDiscountLearnMore = () => {
  return groq`*[_type == "autoship"]{
    autoshipDiscountLearnMoreTitle,
    autoshipDiscountLearnMoreBody,
  }`;
};

module.exports.productDetails = (productId) => {
  return groq`*[_type == "product" && _id == "${productId}"]{
    ${ProductQuery}
  } `;
};
module.exports.getNumberOfCapsules = (variantId) => {
  return groq`*[_type == "productVariant" && _id == "${variantId}"]{
    "numberOfCapsules": numberOfCapsules
    }`;
};
module.exports.getDynamicUrl = (pack) => {
  return groq`*[_type == "dynamicUrl" && isActive == true && slug.current == "${pack}"]{
    products[]->{
      'id': content.shopify.variantId, 
      'numberOfCapsules': content.main.numberOfCapsules
    }
  }`;
};

module.exports.validatePromoCode = (promoCode) => {
  return groq`*[_type == "promoCode" && isActive == true && code == "${promoCode}"]{
    category, 
      code,
      products[]->{
        'id': content.shopify.variantId, 
        'sku': content.shopify.sku, 
        'numberOfCapsules': content.main.numberOfCapsules
      }
    } `;
};

module.exports.getReplacementProducts = (productIds) => {
  return groq`*[_type == "product" && _id in [${productIds}]]{
    'replacementProducts': content.replacement.replacementProducts[] -> {
      ${VariantQuery}
    }
  }`;
};

module.exports.getCartUpsell = () => {
  return groq`*[_type == "upsell" && _id == "40561a8b-b78e-429f-83b7-96051f5589c1"]{
    title,
    products[] {
      ...,
      variant-> {
        ${VariantQuery}
      }
    }
  }`;
};

module.exports.getShopPageCollections = () => {
  return groq`*[_type == "page" && _id == "0fe53f99-54c3-4815-a8af-9390a6eca90d"]{
    'modules': content.main.modules[_type == "shopPage"] {
      collections[]-> {
        ...,
        products[]-> {
        ${ProductQuery}
        }
      }
    },
  }`;
};

module.exports.getHeaderSettings = () => {
  return groq`*[_type == "headerSettings"][0] {
    ...,
  }`;
};

module.exports.getPostsWithPagination = (lastPublishedAt, objectsPerPage) => {
  if (lastPublishedAt) {
    return groq`*[_type == "post" && dateTime(content.main.publishedAt) < dateTime('${lastPublishedAt}')] | order(content.main.publishedAt desc) [0...${objectsPerPage}]{
      ${PostQuery}
    }`;
  } else {
    return groq`*[_type == "post"]| order(content.main.publishedAt desc) [0...${objectsPerPage}]{
      ${PostQuery}
    }`;
  }
};

module.exports.products = productsQuery;
module.exports.posts = postsQuery;

module.exports.pages = pagesQuery;
module.exports.getActiveSales = salesPromoQuery;
module.exports.globalSettings = globalSettingsQuery;
