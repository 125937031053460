import { firestore as defaultFirestore } from "../config/firebase";
import {
  doc,
  getDocs,
  collection,
  setDoc,
  getDoc,
  query,
  where,
  deleteDoc,
  updateDoc,
  limit,
} from "firebase/firestore";
import moment from "moment";
import { isEmpty } from "../helpers/general";
import { createNewError } from "./errors";
import { updateProfile } from "firebase/auth";

const getCustomerAddresses = async (customerId, firestore = defaultFirestore) => {
  const querySnapshot = await getDocs(
    collection(firestore, "customers", customerId, "addresses"),
  );
  let addresses = [];
  querySnapshot.forEach((doc) => {
    addresses.push(doc.data());
  });
  return addresses;
};

const getCustomerTotalNumberOfOrders = async (customerId, firestore = defaultFirestore) => {
  const q = query(
    collection(firestore, "orders"),
    where("customerId", "==", customerId),
  );
  const docsSnap = await getDocs(q);
  if (docsSnap.empty) {
    return 0;
  }
  return docsSnap.size;
};

const findCustomerByAuthId = async (auth_id, firestore = defaultFirestore) => {
  let customer = null;
  const q = query(
    collection(firestore, "customers"),
    where("authId", "==", auth_id),
  );
  const docsSnap = await getDocs(q);
  if (docsSnap.empty) {
    return null;
  }

  docsSnap.forEach((doc) => {
    customer = doc.data();
  });

  return customer;
};

// Convert to function call
const findCustomerByEmail = async (email, firestore = defaultFirestore) => {
  let customer = null;
  const q = query(
    collection(firestore, "customers"),
    where("email", "==", email),
    limit(1),
  );
  const docsSnap = await getDocs(q);
  if (docsSnap.empty) {
    return null;
  }

  docsSnap.forEach((doc) => {
    customer = doc.data();
  });

  return customer;
};

const createNewAddress = async (customerId, address, firestore = defaultFirestore) => {
  try {
    // If content changes please modify test (try, catch blocks proper testing)
    const customerRef = doc(firestore, "customers", customerId);
    const subCollectionRef = collection(customerRef, "addresses");

    await setDoc(doc(subCollectionRef, address.id), address);
  } catch (err) {
    console.error(err);
    console.error("createNewAddress");
    console.error(customerId);
    console.error(address);
  }

  return address;
};

const updateAddress = async (customerId, address, firestore = defaultFirestore) => {
  try {
    const customerRef = doc(firestore, "customers", customerId);
    const subCollectionRef = collection(customerRef, "addresses");
    const addressRef = doc(subCollectionRef, address.id);
    await updateDoc(addressRef, {
      label: address.label,
      firstName: address.firstName,
      lastName: address.lastName,
      companyName: address.companyName,
      addressLine1: address.addressLine1,
      addressLine2: address.addressLine2,
      city: address.city,
      postCode: address.postCode,
      isPostal: address.isPostal,
      ruralDelivery: address.ruralDelivery,
      isVerified: address.isVerified,
      phoneNumber: address.phoneNumber,
      note: address.note,
      dpid: address.dpid,
    });
  } catch (err) {
    console.error(err);
    console.error("updateAddress");
    console.error(customerId);
    console.error(address);
  }

  return address;
};

const batchUpdateDefaultAddress = async (customerId, addresses, firestore = defaultFirestore) => {
  try {
    const customerRef = doc(firestore, "customers", customerId);
    const subCollectionRef = collection(customerRef, "addresses");

    addresses.forEach((address) => {
      const addressRef = doc(subCollectionRef, address.id);
      updateDoc(addressRef, {
        isDefaultAddress: address.isDefaultAddress,
      });
    });
  } catch (err) {
    console.error(err);
    console.error("batchUpdateDefaultAddress");
    console.error(customerId);
    console.error(addresses);
  }
  return addresses;
};

const deleteCustomerAddress = async (customerId, addressId, firestore = defaultFirestore) => {
  try {
    const customerRef = doc(firestore, "customers", customerId);
    const subCollectionRef = collection(customerRef, "addresses");

    await deleteDoc(doc(subCollectionRef, addressId));
  } catch (err) {
    console.error(err);
    console.error("deleteCustomerAddress");
    console.error(customerId);
    console.error(addressId);
  }
};

const createNewCustomer = async (customer, firestore = defaultFirestore) => {
  const newDocRef = doc(collection(firestore, "customers"));
  customer.id = newDocRef.id;
  if (customer.email) {
    customer.email = customer.email.toLowerCase();
  }
  await setDoc(newDocRef, customer);

  return customer;
};

const createOrder = async (
  orderDetails,
  customer,
  lineItems,
  paymentResult,
  paymentMethod,
  signup,
  login,
  findCustomerDetailsByAuthId,
  tags,
  firestore = defaultFirestore
) => {
  const newDocRef = doc(collection(firestore, "orders"));
  const orderLineItems = lineItems?.map((item) => ({
    sku: item.sku,
    productTitle: item.title,
    quantity: item.quantity,
    price: item.price.amount,
    productId: item.product_id,
    variantId: item.variant_id,
    variantTitle: item.variant_title,
    imageSrc: item.image_src,
  }));
  let order = {
    id: newDocRef.id,
    customerId: customer.id,
    shopifyOrderId: orderDetails?.shopifyOrderId || "",
    orderNumber: orderDetails.orderNumber,
    email: orderDetails.email?.toLowerCase(),
    orderType: orderDetails.orderType,
    subscriptionFrequency: orderDetails.subscriptionFrequency,
    createdAt: new Date(),
    shopifyCustomerId: orderDetails?.shopifyCustomerId || "",
    firstName: customer.firstName,
    lastName: customer.lastName,
    total: orderDetails.totalAmount,
    shippingCost: orderDetails.shippingCost,
    note: orderDetails.note,
    appliedDiscount: orderDetails.appliedDiscount,
    shippingAddress: orderDetails.shippingAddress,
    paymentMethod: paymentMethod,
    stripeCustomerId: customer.stripeId || "",
    eligibleLoyaltyPercentage: customer.eligibleLoyaltyPercentage || 5,
    tags: tags || null,
  };
  if (paymentResult?.paymentIntent) {
    order.paymentIntentId = paymentResult.paymentIntent.id || "";
    order.paymentMethodId = paymentResult.paymentIntent.payment_method || "";
    order.paymentIntentAmount = paymentResult.paymentIntent.amount || "";
  }
  if (orderLineItems && orderLineItems.length) {
    order.lineItems = orderLineItems;
  }
  try {
    await setDoc(newDocRef, order);
    await createSubscription(
      order,
      orderDetails.shouldCreateAccount,
      orderDetails.password,
      signup,
      login,
      findCustomerDetailsByAuthId,
    );

    return order;
  } catch (err) {
    await notifyErrorMessage(
      `Error in createOrder for ${order.email}`,
      JSON.stringify(order),
      err
    );
    return order;
  }
};

const notifyErrorMessage = async (
  extraInfo,
  orderStringified,
  error,
) => {
  fetch("/.netlify/functions/send-email", {
    method: "POST",
    body: JSON.stringify({
      to: "dev@magnesium.nz",
      subject: "Urgent! Website error processing order",
      text: "Urgent",
      html: `<p><strong>Message: </strong> ${extraInfo}</p>
      <p><strong>Order: </strong> ${orderStringified}</p>
        <p><strong>Error Name: </strong> ${error.name}</p>
        <p><strong>Error Message: </strong> ${error.message}</p>
        <p><strong>Error Stack: </strong> ${error.stack}</p>
        `,
    }),
  });
};

const createSubscription = async (
  order,
  shouldCreateAccount,
  password,
  signup,
  login,
  findCustomerDetailsByAuthId,
  firestore = defaultFirestore
) => {
  if (order?.orderType === "SUBSCRIPTION") {
    const newDocRef = doc(collection(firestore, "subscriptions"));
    const subscription = {
      id: newDocRef.id,
      customerId: order.customerId,
      shopifyCustomerId: order.shopifyCustomerId,
      email: order.email,
      subscriptionFrequency: order.subscriptionFrequency || 4,
      status: "ACTIVE",
      paymentMethodId: order.paymentMethodId,
      lineItems: order.lineItems,
      shippingAddress: order.shippingAddress,
      stripeCustomerId: order.stripeCustomerId,
      eligibleLoyaltyPercentage: order.eligibleLoyaltyPercentage,
      nextShipment: moment()
        .add(order.subscriptionFrequency || 4, "weeks")
        .toDate(),
    };
    await setDoc(newDocRef, subscription);
    if (shouldCreateAccount && !isEmpty(password)) {
      try {
        signup(order.email?.toLowerCase(), password)
          .then(async (userCred) => {
            updateProfile(userCred.user, {
              displayName: order.shippingAddress?.firstName,
            });
            await updateCustomerAfterPurchase(
              { id: order.customerId },
              userCred.user.uid,
            );
            login(order.email?.toLowerCase(), password).then(
              async (userCred) => {
                await findCustomerDetailsByAuthId(userCred.user?.uid);
              },
            );
          })
          .catch((error) => {
            createNewError({
              createdAt: new Date(),
              source: "createSubscriptionLogin",
              payload: JSON.stringify({
                subscriptionId: newDocRef.id,
                email: order.email?.toLowerCase(),
                password: password,
                order: JSON.stringify(order),
              }),
              message: JSON.stringify(error),
            });
          });
      } catch (err) {
        createNewError({
          createdAt: new Date(),
          source: "createSubscriptionLogin",
          payload: JSON.stringify({
            subscriptionId: newDocRef.id,
            email: order.email?.toLowerCase(),
            password: password,
            order: JSON.stringify(order),
          }),
          message: JSON.stringify(err),
        });
      }
    }

    if (subscription) {
      fetch("./netlify/functions/send-sendgrid-email-template", {
        method: "POST",
        body: JSON.stringify({
          to: order.email?.toLowerCase(),
          replyTo: "pcole@coffeecapsules2u.co.nz",
          emailData: {
            first_name: order.shippingAddress?.firstName,
          },
          templateId: "d-cb1725adddfd4a7cbc221ca0914796bc",
        }),
      });
    }
    return subscription;
  }
};

const updateCustomerDetails = async (customer, firestore = defaultFirestore) => {
  try {
    const customerRef = doc(firestore, "customers", customer.id);
    await updateDoc(customerRef, {
      firstName: customer.firstName,
      lastName: customer.lastName,
      email: customer.email,
      phoneNumber: customer.phoneNumber,
      isPhoneNumberVerified: customer.isPhoneNumberVerified,
      fullName: customer.firstName + " " + customer.lastName,
      dateOfBirth: customer.dateOfBirth || null,
    });
  } catch (err) {
    console.error(err);
    console.error("updateCustomerDetails");
    console.error(customer);
    console.error(JSON.stringify(customer));
  }
  return customer;
};

const updateCustomerAfterPurchase = async (customer, authId, firestore = defaultFirestore) => {
  try {
    const customerRef = doc(firestore, "customers", customer.id);
    await updateDoc(customerRef, {
      authId: authId,
    });
  } catch (err) {
    console.error(err);
    console.error("updateCustomerAfterPurchase");
    console.error(customer);
    console.error(authId);
    console.error(JSON.stringify(customer));
  }

  return customer;
};
const updateDefaultPaymentMethod = async (customerId, paymentId, firestore = defaultFirestore) => {
  try {
    const customerRef = doc(firestore, "customers", customerId);
    await updateDoc(customerRef, {
      defaultPaymentMethod: paymentId,
    });
  } catch (err) {
    console.error(err);
    console.error("updateDefaultPaymentMethod");
    console.error(customerId);
    console.error(paymentId);
  }

  return customerId;
};
const updateCustomerVerifiedPhoneNumber = async (id, phoneNumber, firestore = defaultFirestore) => {
  try {
    const customerRef = doc(firestore, "customers", id);
    await updateDoc(customerRef, {
      phoneNumber: phoneNumber,
      isPhoneNumberVerified: true,
    });
  } catch (err) {
    console.error(err);
    console.error("updateCustomerVerifiedPhoneNumber");
    console.error(id);
    console.error(phoneNumber);
  }
  return id;
};

const getReferral = async (id, firestore = defaultFirestore) => {
  const referralRef = doc(firestore, "referrals", id);
  const docSnap = await getDoc(referralRef);

  if (docSnap.exists()) {
    return docSnap.data();
  } else {
    return null;
  }
}

const setReferralCreditUsed = async (id, firestore = defaultFirestore) => {
  try {
    const referralRef = doc(firestore, "referrals", id);
    await updateDoc(referralRef, {
      creditUsed: true,
      creditUsedDate: new Date(),
    });
  } catch (err) {
    console.error(err);
    console.error("setReferralCreditUsed");
    console.error(id);
  }
  return id;
}

export {
  getCustomerAddresses,
  createNewCustomer,
  createNewAddress,
  updateAddress,
  createOrder,
  findCustomerByAuthId,
  findCustomerByEmail,
  deleteCustomerAddress,
  updateCustomerDetails,
  getCustomerTotalNumberOfOrders,
  updateCustomerAfterPurchase,
  batchUpdateDefaultAddress,
  updateDefaultPaymentMethod,
  updateCustomerVerifiedPhoneNumber,
  getReferral,
  setReferralCreditUsed
};
