import moment from "moment";

const first_time_buyer = "first_time_buyer_deal";
const tags_key = "cc2u_tags";
const isBrowser = typeof window !== `undefined`;

export const setEligibleFirstTimeBuyer = () => {
  if (isBrowser) {
    localStorage.setItem(
      first_time_buyer,
      moment().format("YYYY-MM-DD HH:mm:ss"),
    );
  }
};

export const checkIfEligibleLocalStorageCode = (key, hours) => {
  return checkIfTokenValid(key, hours);
};

export const storeTokens = (tokens) => {
  for (let token of tokens) {
    storeToken(token);
  }
};

export const storeToken = (token) => {
  if (isBrowser) {
    localStorage.setItem(token, moment().format("YYYY-MM-DD HH:mm:ss"));
  }
};

export const checkIfTokenValid = (token, hours) => {
  if (!isBrowser) return false;

  const startTime = localStorage.getItem(token);
  // checks if last checkout session has been more than 24 hours.
  if (
    startTime &&
    moment(moment().format("YYYY-MM-DD HH:mm:ss")).isBefore(
      moment(startTime).add(hours, "hours"),
    )
  ) {
    return true;
  }
  return false;
};

export const storeTags = (tags) => {
  if (isBrowser) {
    const existingTagsString = localStorage.getItem(tags_key);
    let existingTags = [];
    if (existingTagsString) {
      try {
        existingTags = JSON.parse(existingTagsString);
        existingTags = existingTags.filter((tag) =>
          moment(moment().format("YYYY-MM-DD HH:mm:ss")).isBefore(
            moment(tag.date).add(7, "days"),
          ),
        );
      } catch (e) { }
    }
    const existingTagsValues = existingTags.map((tag) => tag.value);

    tags.split(",").forEach((tag) => {
      // Add tag if not already in list
      if (!existingTagsValues.includes(tag)) {
        existingTags.push({
          value: tag,
          date: moment().format("YYYY-MM-DD HH:mm:ss"),
        });
      } else {
        // Update date if reoccurance
        existingTags[existingTagsValues.indexOf(tag)].date = moment().format(
          "YYYY-MM-DD HH:mm:ss",
        );
      }
    });

    localStorage.setItem(tags_key, JSON.stringify(existingTags));
  }
};

export const getTags = () => {
  if (isBrowser) {
    const existingTagsString = localStorage.getItem(tags_key);
    if (existingTagsString) {
      try {
        let existingTags = JSON.parse(existingTagsString);
        existingTags = existingTags.filter((tag) =>
          moment(moment().format("YYYY-MM-DD HH:mm:ss")).isBefore(
            moment(tag.date).add(7, "days"),
          ),
        );
        return existingTags.map((tag) => tag.value);
      } catch (e) {
        return [];
      }
    }
  }
  return [];
};


export const setWasLoggedIn = (value) => {
  try {
  if (isBrowser) {
    localStorage.setItem("wasLoggedIn", Boolean(value));
    if (!value) {
      localStorage.removeItem("wasExpressCustomer");
    }
  }} catch (err) {
    console.error(err)
  }
}

export const getWasLoggedIn = () => {
  try {
    if (isBrowser) {
      return localStorage.getItem("wasLoggedIn") === "true";
    }
  } catch {
    return false;
  }
}

export const setWasExpressCustomer = (value) => {
  if (isBrowser) {
    localStorage.setItem("wasExpressCustomer", Boolean(value));
  }
}

export const getWasExpressCustomer = () => {
  if (isBrowser) {
    const wasExpressCustomer = localStorage.getItem("wasExpressCustomer");
    if (wasExpressCustomer === "true") {
      return "true";
    } else if (wasExpressCustomer === "false") {
      return "false";
    }
  }
  return "unknown";
}