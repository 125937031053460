import * as React from "react";
import fetch from "isomorphic-fetch";
import Client from "shopify-buy";
import {
  checkIfValidCheckoutSession,
  checkIfStillValidForAutoshipDiscount,
} from "../../helpers/date/date";

import { checkSkuForCapsulesTotal } from "../../helpers/shopify/converter"
import moment from "moment";
import { sanity } from "../../config/sanity";
import {
  getAutoshipDiscount,
  getAutoshipDiscountLearnMore,
} from "../../api/queries";
import axios from "axios";

const client = Client.buildClient(
  {
    domain: process.env.GATSBY_SHOPIFY_STORE_URL,
    storefrontAccessToken: process.env.GATSBY_SHOPIFY_STOREFRONT_ACCESS_TOKEN,
  },
  fetch,
);

const defaultValues = {
  cart: [],
  isOpen: false,
  loading: false,
  onOpen: () => { },
  onClose: () => { },
  addVariantToCart: () => { },
  removeLineItem: () => { },
  updateLineItem: () => { },
  client,
  checkout: {
    lineItems: [],
  },
  checkoutIsApplicableForAutoship: false,
  orderType: "ONE_OFF",
  setOrderType: (orderType) => { },
  subscriptionFrequency: 4,
  setSubscriptionFrequency: (subscriptionFrequency) => { },
  autoshipDiscount: {
    autoshipDiscountAmount: 0,
    updateAutoshipDiscount: async () => { },
    setAutoshipDiscountValid: () => { },
    autoshipDiscountLearnMore: {
      title: "",
      body: "",
      loaded: false,
    },
  },
};

export const StoreContext = React.createContext(defaultValues);

const isBrowser = typeof window !== `undefined`;
const localStorageKey = `shopify_checkout_id`;
const localStorageDateKey = `shopify_checkout_date`;
const autoshipDiscountValidTill = `autoship_discount_valid_till`;

export const StoreProvider = ({ children }) => {
  const [checkout, setCheckout] = React.useState(defaultValues.checkout);
  const [loading, setLoading] = React.useState(false);
  const [shouldAddStoreParams, setShouldAddStoreParams] = React.useState(true);
  const [didJustAddToCart, setDidJustAddToCart] = React.useState(false);
  const [totalNumberOfCapsules, setTotalNumberOfCapsules] = React.useState(0);
  const [hasBeenCleared, setHasBeenCleared] = React.useState(false);
  const [checkoutInitialized, setCheckoutInitialized] = React.useState(false);

  const [variantsInAutoshipCollection, setVariantsInAutoshipCollection] =
    React.useState([]);

  const [activeAutomaticDiscounts, setActiveAutomaticDiscounts] = React.useState({});
  const [checkoutIsApplicableForAutoship, setCheckoutIsApplicableForAutoship] =
    React.useState(false);

  const [orderType, setOrderType] = React.useState("ONE_OFF");
  const [subscriptionFrequency, setSubscriptionFrequency] = React.useState(4);

  const [autoshipDiscountLearnMoreTitle, setAutoshipDiscountLearnMoreTitle] =
    React.useState("");
  const [autoshipDiscountLearnMoreBody, setAutoshipDiscountLearnMoreBody] =
    React.useState("");
  const [autoshipDiscountLearnMoreLoaded, setAutoshipDiscountLearnMoreLoaded] =
    React.useState(false);
  const [autoshipDiscountAmount, setAutoshipDiscountAmount] = React.useState(0);

  const setCheckoutItem = (checkout) => {
    if (isBrowser) {
      localStorage.setItem(localStorageKey, checkout.id);
    }

    if (checkout) {
      const items =
        checkout.lineItems
          ?.filter((item) => item.variant == null)
          .map((item) => item.id) || [];
      client.checkout.removeLineItems(checkout.id, items).then((res) => {
        setCheckout(res);
      });
    } else {
      setCheckout(checkout);
    }
  };

  React.useEffect(() => {
    const initializeCheckout = async () => {
      setCheckoutInitialized(false);
      const hasValidCheckout = isBrowser
        ? checkIfValidCheckoutSession(localStorage.getItem(localStorageDateKey))
        : null;
      const existingCheckoutID = isBrowser
        ? localStorage.getItem(localStorageKey)
        : null;
      console.log(`CheckoutId StoreContext: ${existingCheckoutID}`);

      if (hasValidCheckout) {
        if (existingCheckoutID && existingCheckoutID !== `null`) {
          try {
            const existingCheckout =
              await client.checkout.fetch(existingCheckoutID);
            if (!existingCheckout.completedAt) {
              setCheckoutItem(existingCheckout);
              return;
            }
          } catch (e) {
            localStorage.setItem(localStorageKey, null);
          }
        }
      } else {
        if (isBrowser) {
          localStorage.setItem(
            localStorageDateKey,
            moment().format("YYYY-MM-DD HH:mm:ss"),
          );
        }
      }

      if (!hasBeenCleared) {
        const newCheckout = await client.checkout.create();
        setCheckoutItem(newCheckout);
        setHasBeenCleared(true);
      } else {
        const newCheckout = await client.checkout.create();
        setCheckoutItem(newCheckout);
      }
      setCheckoutInitialized(true);
    };

    initializeCheckout();

    console.log("Initilaize checkout");
    // Initialize the values so they are available even when layout component is not mounted
    updateAutoshipDiscount();
  }, []);

  React.useEffect(() => {
    // Load autoship collection
    const collectionId = "gid://shopify/Collection/300780748897";
    client.collection
      .fetchWithProducts(collectionId, { productsFirst: 50 })
      .then((collection) => {
        // Do something with the collection
        const allVariants = collection.products.flatMap((product) =>
          product.variants.map((variant) => variant.id),
        );
        setVariantsInAutoshipCollection(allVariants);
      });
  }, [])

  React.useEffect(() => {
    // Get active automatic discounts on the store
    axios.get("/.netlify/functions/get-automatic-bxgy-discounts").then((res) => {
      if (res.status === 200) {
        const automaticDiscounts = res.data;
        let variantIdToApplication = {};
        automaticDiscounts.forEach((discount) => {
          discount.customerBuysVariantIds.forEach((variantId) => {
            if (variantId in variantIdToApplication) {
              // Variant ID already exists in the dictionary, compare prices
              if (discount.customerGetsPrice > variantIdToApplication[variantId].customerGetsPrice) {
                // If the current discount price is higher, update it in the dictionary
                variantIdToApplication[variantId] = discount;
              }
            } else {
              // Variant ID doesn't exist in the dictionary, add it
              variantIdToApplication[variantId] = discount;
            }
          });
        });
        setActiveAutomaticDiscounts(variantIdToApplication);
      }
    }).catch((err) => { console.error(err) });
  }, [])

  React.useEffect(() => {
    if (checkout?.lineItems) {
      const numberOfCapsules = checkout.lineItems.reduce((total, item) => {
        const totalNumberCapsules = Number(
          item.customAttributes.find((a) => a.key === "numberOfCapsules")
            ?.value || 0,
        );
        return total + totalNumberCapsules * item.quantity;
      }, 0);

      setTotalNumberOfCapsules(numberOfCapsules);
      const productVariants = checkout.lineItems.map(
        (lineItem) => lineItem.variant.id,
      );

      var isValidForAutoship =
        productVariants.length > 0 && productVariants.filter(
          (product) => !variantsInAutoshipCollection.includes(product),
        ).length === 0;
      setCheckoutIsApplicableForAutoship(isValidForAutoship);
    }
  }, [checkout?.lineItems, variantsInAutoshipCollection]);

  React.useEffect(() => {
    const loadAutoshipLearnMore = async () => {
      const autoshipDiscountLearnMore = (
        await sanity.fetch(getAutoshipDiscountLearnMore())
      )[0];
      setAutoshipDiscountLearnMoreTitle(
        autoshipDiscountLearnMore.autoshipDiscountLearnMoreTitle,
      );
      setAutoshipDiscountLearnMoreBody(
        autoshipDiscountLearnMore.autoshipDiscountLearnMoreBody,
      );
      setAutoshipDiscountLearnMoreLoaded(true);
    };

    loadAutoshipLearnMore();
  }, []);

  React.useEffect(() => {
    if (!checkoutIsApplicableForAutoship) {
      setOrderType("ONE_OFF");
    }
  }, [checkoutIsApplicableForAutoship]);

  React.useEffect(() => {
    // Check if turning off autoship, if so then clear autoship specific discounts
    if (orderType === "ONE_OFF" && checkout?.discountApplications?.length > 0 && checkout.discountApplications[0].code) {
      if (checkout.discountApplications[0].code.toUpperCase().includes("AUTOSHIP")) {
        removeDiscountFromCart();
      }
    }
  }, [orderType]);

  const createNewCheckout = async () => {
    const newCheckout = await client.checkout.create();
    setCheckoutItem(newCheckout);
  };

  const shouldAddExtraProduct = (variantId, quantity) => {
    // If no possible discount
    if (!(variantId in activeAutomaticDiscounts)) {
      return null;
    }

    const possibleDiscount = activeAutomaticDiscounts[variantId];
    if (!possibleDiscount) {
      return null;
    }

    // Check the discount is still valid
    const now = moment.utc();
    if (possibleDiscount?.endsAt && !now.isBefore(possibleDiscount?.endsAt)) {
      return null;
    }

    // Check that no automatic discount is already applied, expect for the one that we are applying
    if (checkout?.discountApplications?.length > 0 && checkout.discountApplications[0].title && checkout.discountApplications[0].title !== possibleDiscount?.title) {
      // There could be a code already on there. But no automatic discounts
      return null;
    }

    // Prepare the product to add
    return {
      variantId: possibleDiscount.customerGetsVariantId,
      quantity: possibleDiscount.customerGetsQuantity * quantity,
      customAttributes: [
        {
          key: "numberOfCapsules", value: checkSkuForCapsulesTotal(possibleDiscount.customerGetsSku, 1).toString()
        }
      ]
    }
  };

  const addVariantToCart = (variantId, quantity, variant) => {
    setLoading(true);
    const checkoutID = checkout.id;
    const totalNumberOfCapsules = variant?.numberOfCapsules || 0;
    const customAttributes = [
      { key: "numberOfCapsules", value: totalNumberOfCapsules.toString() },
    ];
    const lineItemsToUpdate = [
      {
        variantId,
        quantity: parseInt(quantity, 10),
        customAttributes,
      },
    ];

    // Check if should add extra for automatic discount
    try {
      const extraAddToCart = shouldAddExtraProduct(variantId, quantity);
      if (extraAddToCart) {
        lineItemsToUpdate.push(extraAddToCart);
      }
    } catch (err) {
      console.error("Auto Discount Check Error:", err)
    }


    return client.checkout
      .addLineItems(checkoutID, lineItemsToUpdate)
      .then((res) => {
        setCheckout(res);
        setLoading(false);
      })
      .catch((err) => console.error("addLIneItem", err));
  };

  const fetchProductRecommedations = (productId) => {
    return client.product
      .fetchRecommendations(productId)
      .then((products) => { })
      .catch((err) => console.log(err));
  };

  const addMultipleVariantsToCartFromUrl = (lineItemsToUpdate) => {
    setLoading(true);
    const checkoutID = checkout.id;
    return client.checkout
      .addLineItems(checkoutID, lineItemsToUpdate)
      .then((res) => {
        setCheckout(res);
        setLoading(false);
      });
  };

  const addDiscountToCart = async (discountCode) => {
    setLoading(true);

    // Actually applies discount to cart
    const checkoutID = checkout.id;
    const res = await client.checkout.addDiscount(checkoutID, discountCode);
    if (res.discountApplications?.length > 0) {
      const foundDiscountCode = res.discountApplications.find(
        (discount) => {
          return discount?.code?.toLowerCase() === discountCode.toLowerCase().trim()
        },
      );
      if (foundDiscountCode && foundDiscountCode.applicable) {
        setCheckout(res);
        setLoading(false);
        return "";
      } else {
        await removeDiscountFromCart();
        return "This discount code is not applicable";
      }
    } else {
      if (res.userErrors?.length) {
        return res.userErrors[0]?.message;
      }
      return "Discount Code is not applicable for some products in your cart";
    }
  };

  const removeDiscountFromCart = () => {
    setLoading(true);
    const checkoutID = checkout.id;
    const lineItemsId = checkout.lineItems.filter((item) =>
      item.customAttributes?.find(
        (c) => c.key === "readOnly" && c.value === "true",
      ),
    );
    // if(lineItemsId && lineItemsId.length){
    //   console.log("id", lineItemsId.map(item => item.id))
    //   client.checkout
    //   .removeLineItems(checkoutID, lineItemsId.map(item => item.id))
    // }
    return client.checkout.removeDiscount(checkoutID).then((res) => {
      if (lineItemsId && lineItemsId.length) {
        return client.checkout
          .removeLineItems(
            checkoutID,
            lineItemsId.map((item) => item.id),
          )
          .then((resDelete) => {
            setCheckout(resDelete);
            setLoading(false);
          });
      }
      setCheckout(res);
      setLoading(false);
      setDidJustAddToCart(true);
      setTimeout(() => setDidJustAddToCart(false), 3000);
    });
  };

  const removeLineItem = async (checkoutID2, lineItemID) => {
    setLoading(true);
    const checkoutID = checkout?.id;
    return client.checkout
      .removeLineItems(checkoutID, [lineItemID])
      .then((res) => {
        setCheckout(res);
        setLoading(false);
      })
      .catch(async (err) => {
        setLoading(false);
        if (checkoutID) {
          if (checkout?.lineItems.length === 1) {
            const newCheckout = await client.checkout.create();
            setCheckoutItem(newCheckout);
          }
        }
      });
  };

  const updateLineItem = (checkoutID, lineItemID, quantity, item) => {
    setLoading(true);
    const lineItemsToUpdate = [
      { id: lineItemID, quantity: parseInt(quantity, 10) },
    ];

    return client.checkout
      .updateLineItems(checkoutID, lineItemsToUpdate)
      .then((res) => {
        setCheckout(res);
        setLoading(false);
      });
  };

  const clearCheckout = async () => {
    const checkoutID = checkout?.id;
    return client.checkout
      .removeLineItems(
        checkoutID,
        checkout.lineItems.map((item) => item.id),
      )
      .then((res) => {
        setCheckout(res);
        setLoading(false);
      })
      .catch(async (err) => {
        setLoading(false);
        const newCheckout = await client.checkout.create();
        setCheckoutItem(newCheckout);
      });
  };

  const setAutoshipDiscountValid = () => {
    if (isBrowser) {
      localStorage.setItem(
        autoshipDiscountValidTill,
        moment().add(1, "hours").format("YYYY-MM-DD HH:mm:ss"),
      );
    }
  };

  const getIsAutoshipDiscountStillValid = () => {
    if (isBrowser) {
      return checkIfStillValidForAutoshipDiscount(
        localStorage.getItem(autoshipDiscountValidTill),
      );
    }
    return false;
  };

  const updateAutoshipDiscount = async () => {
    const { autoshipDiscountActiveFor, autoshipDiscountRate } = (
      await sanity.fetch(getAutoshipDiscount())
    )[0];
    switch (autoshipDiscountActiveFor) {
      case "no_one":
        setAutoshipDiscountAmount(0);
        break;
      case "autoshipD":
        if (getIsAutoshipDiscountStillValid()) {
          setAutoshipDiscountAmount(autoshipDiscountRate);
        } else {
          setAutoshipDiscountAmount(0);
        }
        break;
      case "everyone":
        setAutoshipDiscountAmount(autoshipDiscountRate);
        break;
    }
  };

  return (
    <StoreContext.Provider
      value={{
        ...defaultValues,
        addVariantToCart,
        addMultipleVariantsToCartFromUrl,
        removeLineItem,
        updateLineItem,
        addDiscountToCart,
        removeDiscountFromCart,
        createNewCheckout,
        clearCheckout,
        fetchProductRecommedations,
        checkout,
        checkoutInitialized,
        loading,
        didJustAddToCart,
        totalNumberOfCapsules,
        setShouldAddStoreParams,
        shouldAddStoreParams,
        checkoutIsApplicableForAutoship,
        orderType,
        setOrderType,
        subscriptionFrequency,
        setSubscriptionFrequency,
        autoshipDiscount: {
          autoshipDiscountAmount: autoshipDiscountAmount,
          updateAutoshipDiscount: updateAutoshipDiscount,
          setAutoshipDiscountValid: setAutoshipDiscountValid,
          autoshipDiscountLearnMore: {
            title: autoshipDiscountLearnMoreTitle,
            body: autoshipDiscountLearnMoreBody,
            loaded: autoshipDiscountLearnMoreLoaded,
          },
        },
      }}
    >
      {children}
    </StoreContext.Provider>
  );
};
