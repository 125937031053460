import React, { createContext, useState, useContext } from "react";
import { v4 as uuidv4 } from "uuid";
const defaultState = {
  email: "",
  shippingAddress: { id: uuidv4() },
};

export const CheckoutContext = createContext(defaultState);

export const CheckoutContextProvider = ({ children }) => {
  const [state, setState] = useState(defaultState);

  const setEmailForGuestCheckout = async (email) => {
    setState({
      ...state,
      email: email?.toLowerCase(),
    });
  };
  const setShippingAddress = async (shippingAddress) => {
    setState({
      ...state,
      shippingAddress: shippingAddress,
    });
  };
  const resetShippingAddress = async () => {
    setState({
      ...state,
      shippingAddress: { id: uuidv4() },
    });
  };
  const resetCheckout = () => {
    setState(defaultState);
  };
  return (
    <CheckoutContext.Provider
      value={{
        email: state.email,
        shippingAddress: state.shippingAddress,
        setEmailForGuestCheckout,
        setShippingAddress,
        resetCheckout,
        resetShippingAddress,
      }}
    >
      {children}
    </CheckoutContext.Provider>
  );
};

export function useCheckout() {
  return useContext(CheckoutContext);
}
