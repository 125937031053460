import { firestore } from "../config/firebase";
import {
  doc,
  getDocs,
  collection,
  setDoc,
  getDoc,
  query,
  where,
  deleteDoc,
  updateDoc,
  limit,
} from "firebase/firestore";

const findSubscriptionsByCustomerId = async (customerId) => {
  let subscriptions = [];
  let temp_subscriptions = [];
  const q = query(
    collection(firestore, "subscriptions"),
    where("customerId", "==", customerId),
  );

  const docsSnap = await getDocs(q);
  if (docsSnap.empty) {
    return subscriptions;
  }

  docsSnap.forEach((doc) => {
    temp_subscriptions.push(doc.data());
  });

  temp_subscriptions.forEach(async (sub) => {
    if (sub.status !== "DELETED") {
      if (sub.lineItems && sub?.lineItems?.length > 0) {
        subscriptions.push(sub);
      } else {
        await updateSubscriptionStatus(sub, "DELETED");
      }
    }
  });

  return subscriptions;
};

const updateSubscriptionPayment = async (subscription, paymentMethodId) => {
  try {
    const subRef = doc(firestore, "subscriptions", subscription.id);
    await updateDoc(subRef, {
      paymentMethodId: paymentMethodId,
    });
    return subscription;
  } catch (err) {
    console.error(err);
    console.error("updateSubscriptionPayment");
    console.error(subscription);
    console.error(paymentMethodId);
    console.error(JSON.stringify(subscription));
  }
  return null;
};

const updateSubscriptionStatus = async (subscription, status) => {
  try {
    subscription.status = status;
    const subRef = doc(firestore, "subscriptions", subscription.id);
    await updateDoc(subRef, {
      status: status,
    });
    return subscription;
  } catch (err) {
    console.error(err);
    console.error("updateSubscriptionStatus");
    console.error(subscription);
    console.error(status);
    console.error(JSON.stringify(subscription));
  }
  return null;
};
const updateSubscriptionAddress = async (subscription, shippingAddress) => {
  try {
    subscription.shippingAddress = shippingAddress;
    const subRef = doc(firestore, "subscriptions", subscription.id);
    await updateDoc(subRef, {
      shippingAddress: shippingAddress,
    });
    return subscription;
  } catch (err) {
    console.error(err);
    console.error("updateSubscriptionAddress");
    console.error(subscription);
    console.error(shippingAddress);
    console.error(JSON.stringify(subscription));
  }
  return null;
};

const updateSubscriptionPaymentMethod = async (subscription, paymentId) => {
  try {
    subscription.paymentMethodId = paymentId;
    const subRef = doc(firestore, "subscriptions", subscription.id);
    await updateDoc(subRef, {
      paymentMethodId: paymentId,
    });
    return subscription;
  } catch (err) {
    console.error(err);
    console.error("updateSubscriptionPaymentMethod");
    console.error(subscription);
    console.error(paymentId);
    console.error(JSON.stringify(subscription));
  }
  return null;
};

const updateAutoshipWithMissingPaymentMethod = async (
  customerId,
  paymentMethodId,
) => {
  let subscriptions = await findSubscriptionsByCustomerId(customerId);
  if (subscriptions?.length > 0) {
    let _subscriptionsWithoutPayment = subscriptions.filter(
      (s) => s.status === "ACTIVE" && !s.paymentMethodId,
    );
    if (_subscriptionsWithoutPayment?.length > 0) {
      _subscriptionsWithoutPayment.forEach((s) =>
        updateSubscriptionPaymentMethod(s, paymentMethodId),
      );
    }
  }
};

const updateAutoshipRemovedPaymentMethod = async (
  customerId,
  oldPaymentMethodId,
  newPaymentMethodId,
) => {
  let subscriptions = await findSubscriptionsByCustomerId(customerId);
  if (subscriptions?.length > 0) {
    let _subscriptionsPayment = subscriptions.filter(
      (s) => s.status === "ACTIVE" && s.paymentMethodId === oldPaymentMethodId,
    );
    if (_subscriptionsPayment?.length > 0) {
      _subscriptionsPayment.forEach((s) =>
        updateSubscriptionPaymentMethod(s, newPaymentMethodId),
      );
    }
  }
};

const updateSubscriptionFrequency = async (
  subscription,
  nextShipment,
  subscriptionFrequency,
) => {
  try {
    subscription.nextShipment = nextShipment;
    subscription.subscriptionFrequency = subscriptionFrequency;
    const subRef = doc(firestore, "subscriptions", subscription.id);
    await updateDoc(subRef, {
      nextShipment: nextShipment,
      subscriptionFrequency: subscriptionFrequency,
    });
    return subscription;
  } catch (err) {
    console.error(err);
    console.error("updateSubscriptionFrequency");
    console.error(subscription);
    console.error(nextShipment);
    console.error(subscriptionFrequency);
    console.error(JSON.stringify(subscription));
  }
  return null;
};

const updateSubscriptionLineItems = async (subscription, lineItems) => {
  try {
    subscription.lineItems = lineItems;
    subscription.status =
      lineItems && lineItems.length > 0 ? "ACTIVE" : "PAUSED";
    const subRef = doc(firestore, "subscriptions", subscription.id);
    await updateDoc(subRef, {
      lineItems: lineItems,
      status: subscription.status,
    });
    return subscription;
  } catch (err) {
    console.error(err);
    console.error("updateSubscriptionLineItems");
    console.error(subscription);
    console.error(JSON.stringify(lineItems));
    console.error(JSON.stringify(subscription));
  }
  return null;
};

export {
  findSubscriptionsByCustomerId,
  updateSubscriptionStatus,
  updateSubscriptionPaymentMethod,
  updateSubscriptionAddress,
  updateSubscriptionFrequency,
  updateSubscriptionLineItems,
  updateSubscriptionPayment,
  updateAutoshipWithMissingPaymentMethod,
  updateAutoshipRemovedPaymentMethod,
};
