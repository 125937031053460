export const convertIdFromGraphql = (graphql_id) => {
  if (!graphql_id) {
    return;
  }
  return graphql_id.substring(graphql_id.lastIndexOf("/") + 1);
};
export const convertIdToShopifyGraphql = (id, type) => {
  if (id && type) {
    return `gid://shopify/${type}/${id}`;
  }
  return;
};
export const convertCheckoutLineItems = (checkout) => {
  let lineItems = [];
  if (checkout && checkout.lineItems?.length > 0) {
    checkout.lineItems.forEach((item) => {
      const lineItem = {
        sku: item.variant?.sku,
        title: item.title,
        quantity: item.quantity,
        price: item.variant?.price,
        product_id: item.variant?.product?.id,
        variant_id: item.variant?.id,
        variant_title: item.variant?.title,
        image_src: item.variant?.image?.src,
        // variant_id: convertIdFromGraphql(
        //   item.variableValues?.lineItems[0]?.variantId
        // ),
      };
      lineItems.push(lineItem);
    });
  }

  return lineItems;
};

export const convertCheckoutLineItemsForStripe = (checkout) => {
  let lineItems = "";
  if (checkout && checkout.lineItems?.length > 0) {
    checkout.lineItems.forEach((item) => {
      lineItems = lineItems + `${item.variant?.sku}x${item.quantity} `;
    });
  }
  return lineItems;
};

export const checkSkuForCapsulesTotal = (sku, quantity) => {
  let totalNumberOfCapsules = 0;
  if (sku) {
    if (sku === "NZM_Sampler_Mixed" || sku === "COMP_Trial_Decaf") {
      totalNumberOfCapsules = 60 * quantity;
    } else if (sku === "COMP_Sampler_Mixed") {
      totalNumberOfCapsules = 50 * quantity;
    } else if (sku.toLowerCase().includes("sampler_mixed_")) {
      totalNumberOfCapsules = 70 * quantity;
    } else if (sku === "NZM_Trial_PHC") {
      totalNumberOfCapsules = 80 * quantity;
    } else if (sku.includes("180")) {
      totalNumberOfCapsules = 180 * quantity;
    } else if (sku.includes("120")) {
      totalNumberOfCapsules = 120 * quantity;
    } else if (sku.includes("140")) {
      totalNumberOfCapsules = 140 * quantity;
    } else if (sku.includes("240")) {
      totalNumberOfCapsules = 240 * quantity;
    } else if (sku.includes("360")) {
      totalNumberOfCapsules = 360 * quantity;
    } else if (sku.includes("20")) {
      totalNumberOfCapsules = 20 * quantity;
    } else if (sku.includes("40")) {
      totalNumberOfCapsules = 40 * quantity;
    } else if (sku.includes("60")) {
      totalNumberOfCapsules = 60 * quantity;
    } else if (sku.includes("80")) {
      totalNumberOfCapsules = 80 * quantity;
    } else if (sku.includes("10")) {
      totalNumberOfCapsules = 10 * quantity;
    } else {
      const split = sku?.split("_");
      if (split && split.length > 2) {
        const potentialTotal = split[1];
        if (potentialTotal && !isNaN(potentialTotal)) {
          totalNumberOfCapsules = Number(potentialTotal) * quantity;
        } else if (potentialTotal && !isNaN(split[2])) {
          totalNumberOfCapsules = Number(split[2]) * quantity;
        }
      }
    }
  }

  return totalNumberOfCapsules;
};

export const checkDiscontinuedVariants = (name, sku) => {
  if (name === "Variety Mixed Pack | 120 Capsules") {
    return "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC80MDQyMzgzMjQyMDQ0OQ=="; // Mixed Pack 120
  } else if (name === "Hot Chocolate - 20 Capsule Pack") {
    return "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC80MDQzMDA5Mjg3NzkyMQ=="; // Hot Chocolate - 20 pack
  } else if (sku === "NZM_120_Mixed") {
    return "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC80MDQyMzgzMjQyMDQ0OQ=="; // Mixed Pack 120
  } else if (sku === "GLM") {
    return "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC80MDQzMDEwMzI2NTM3Nw=="; // Gleam 2 Pack
  }
  return "";
};

export const checkIfItemReadOnly = (lineItem, readOnly) => {
  if (lineItem && lineItem.customAttributes?.length) {
    if (
      lineItem.customAttributes.find(
        (c) => c.key === "readOnly" && c.value === "true",
      )
    ) {
      return true;
    }
  }
  return readOnly;
};
