import React from "react";
import moment from "moment";
import Holidays from "date-holidays";

export const getEstimatedShipping = (threshold) => {
  const thresholdTime = moment(threshold, "h:mma");
  const currentTime = moment();

  if (currentTime.isBefore(thresholdTime)) {
    const shippingEta = {
      formatedDate: moment().format("[Today], DD MMM YYYY"),
      descriptiveDate: "ships Today",
      date: moment(),
    };
    return shippingEta;
  } else {
    do {
      currentTime.add(1, "days");
    } while (
      currentTime.day() === 0 ||
      currentTime.day() === 6 ||
      isHoliday(currentTime.format("YYYY-MM-DD"))
    );

    if (currentTime.diff(thresholdTime, "days") === 1) {
      const shippingEta = {
        formatedDate: moment().add(1, "days").format("[Tomorrow], DD MMM YYYY"),
        descriptiveDate: "ships Tomorrow",
        date: moment().add(1, "days"),
      };
      return shippingEta;
    }
  }
  const shippingEta = {
    formatedDate: currentTime.format("dddd, DD MMM YYYY"),
    descriptiveDate: `ships on ${currentTime.format("dddd")}`,
    date: currentTime,
  };
  return shippingEta;
};

export const isHoliday = (dateString) => {
  const dateHolidays = new Holidays("NZ");
  const holidays = dateHolidays.getHolidays(dateString.substring(0, 4));
  const foundDate = holidays.find(
    (h) => new Date(dateString) >= h.start && new Date(dateString) <= h.end,
  );
  return foundDate ? true : false;
};

export const getEstimatedDelivery = (shippingObj, shippingAddress) => {
  const shippingDate = shippingObj?.date;
  if (shippingDate) {
    do {
      shippingDate.add(1, "days");
    } while (
      shippingDate.day() === 0 ||
      shippingDate.day() === 6 ||
      isHoliday(shippingDate.format("YYYY-MM-DD"))
    );

    if (shippingAddress && shippingAddress.ruralDelivery) {
      shippingDate.add(2, "days");
    } else if (
      shippingAddress &&
      shippingAddress.postCode &&
      parseInt(shippingAddress.postCode.trim()) >= 7000
    ) {
      shippingDate.add(1, "days");
    }

    const deliveryEta = {
      formatedDate: shippingDate.format("dddd, DD MMM YYYY"),
      date: shippingDate,
    };
    return deliveryEta;
  }
};

export const getSubscriptionFrequencyList = () => {
  return [
    {
      label: (
        <div>
          Deliver every <b>2 weeks</b>
        </div>
      ),
      value: 2,
    },
    {
      label: (
        <div>
          Deliver every <b>3 weeks</b>
        </div>
      ),
      value: 3,
    },
    {
      label: (
        <div>
          Deliver every <b>4 weeks</b> (most common)
        </div>
      ),
      value: 4,
    },
    {
      label: (
        <div>
          Deliver every <b>5 weeks</b>
        </div>
      ),
      value: 5,
    },
    {
      label: (
        <div>
          Deliver every <b>6 weeks</b>
        </div>
      ),
      value: 6,
    },
    {
      label: (
        <div>
          Deliver every <b>7 weeks</b>
        </div>
      ),
      value: 7,
    },
    {
      label: (
        <div>
          Deliver every <b>8 weeks</b>
        </div>
      ),
      value: 8,
    },
  ];
};
export const checkIfValidCheckoutSession = (lastCheckoutSession) => {
  // checks if last checkout session has been more than 24 hours.
  if (
    lastCheckoutSession &&
    moment(moment().add(-24, "hours").format("YYYY-MM-DD HH:mm:ss")).isBefore(
      moment(lastCheckoutSession),
    )
  ) {
    return true;
  }
  return false;
};

export const checkIfStillValidForAutoshipDiscount = (
  subscriptionDiscountValidTill,
) => {
  if (
    subscriptionDiscountValidTill &&
    moment(moment().format("YYYY-MM-DD HH:mm:ss")).isBefore(
      moment(subscriptionDiscountValidTill),
    )
  ) {
    return true;
  }
  return false;
};
