import axios from "axios";
import CryptoES from 'crypto-es'
import Cookies from 'js-cookie';


const hash = (string) => {
    return CryptoES.SHA256(string).toString(CryptoES.enc.Hex);
};

type contentIdType = { id: string, quantity: number, item_price: number };

type contentsType = contentIdType[];
const generateContentsFromCheckout = (checkout) => {
    const contents: contentsType = [];
    checkout?.lineItems?.forEach((lineItem) => {
        contents.push({
            id: lineItem?.variant?.sku,
            quantity: lineItem?.quantity,
            item_price: Number(lineItem?.variant?.price?.amount),
        })
    })
    return contents;
};

type customerType = {
    email?: string,
    phone?: string,
    firstName?: string,
    lastName?: string,
    id?: string,
};

type payloadType = {
    event_name: string,
    event_time: number,
    event_id?: string | null,
    action_source: string,
    event_source_url?: string | null,
    user_data: {
        em: (string | null)[],
        ph: (string | null)[],
        fn: (string | null)[],
        ln: (string | null)[],
        client_user_agent: string,
        external_id: (string | null)[],
        fbc?: string | null,
        fbp?: string | null,
    },
    content_category?: string | null,
    custom_data: {
        currency?: string | null,
        value?: number | null,
        contents?: string | null,
    },
}

const generatePayload = (
    event_name: string,
    customer: customerType,
    event_id: string | null,

) => {
    // Event Data
    const event_time = Math.round(Date.now() / 1000);
    const action_source = "website";
    const event_source_url = window?.location?.href || null;

    // Session Data
    const client_user_agent = window?.navigator?.userAgent;
    const fbp = Cookies.get("_fbp");
    const fbc = Cookies.get("_fbc");

    // Customer Data
    const hashed_email = customer?.email ? hash(customer.email.toLowerCase()) : null;
    const hashed_phone = customer?.phone ? hash(customer.phone.replace("+", "")) : null;
    const hashed_first_name = customer?.firstName ? hash(customer.firstName.toLowerCase()) : null;
    const hashed_last_name = customer?.lastName ? hash(customer.lastName.toLowerCase()) : null;
    const hashed_customer_id = customer?.id ? hash(customer.id) : null;

    const payload: payloadType = {
        event_name: event_name,
        event_time: event_time,
        action_source: action_source,
        event_source_url: event_source_url,
        user_data: {
            em: [hashed_email],
            ph: [hashed_phone],
            fn: [hashed_first_name],
            ln: [hashed_last_name],
            client_user_agent: client_user_agent,
            external_id: [hashed_customer_id],
            fbc: fbc,
            fbp: fbp,
        },
        content_category: null,
        custom_data: {},
    }

    if (event_id) {
        payload.event_id = event_id;
    }

    return payload;
}

export const pushPixelPurchaseTracking = async (transactionId: string, customer: customerType, checkout, totalAmount: number) => {
    let payload = generatePayload("Purchase", customer, transactionId);

    // Custom Data
    const currency = "NZD";
    const purchase_value = totalAmount ? Number(totalAmount) : null;
    const contents = JSON.stringify(generateContentsFromCheckout(checkout));

    // Add purchase custom_data to payload
    payload = {
        ...payload,
        custom_data: {
            currency: currency,
            value: purchase_value,
            contents: contents
        }
    }

    axios.post("/.netlify/functions/send-event-to-meta-pixel", {
        payload: payload
    }).catch((e) => console.error(e));
}

export const pushPixelViewContent = async (isLandingPage: boolean, pageTitle: string, customer: customerType) => {
    if (!isLandingPage) return;

    let payload = generatePayload("ViewContent", customer, null);

    payload = {
        ...payload,
        content_category: pageTitle
    }

    axios.post("/.netlify/functions/send-event-to-meta-pixel", {
        payload: payload
    }).catch((e) => console.error(e));
}

export const pushPixelPageView = async (customer: customerType) => {
    let payload = generatePayload("PageView", customer, null);

    axios.post("/.netlify/functions/send-event-to-meta-pixel", {
        payload: payload
    }).catch((e) => console.error(e));
}

export const pushPixelAddVariantToCart = async (variantId: string, quantity : number, customer : customerType) => {
    let payload = generatePayload("AddToCart", customer || {}, null);
    payload.custom_data.contents = JSON.stringify([{id: variantId, quantity: quantity}]);

    axios.post("/.netlify/functions/send-event-to-meta-pixel", {
        payload: payload
    }).catch((e) => console.error(e));

}

export const pushPixelInitiateCheckout = async (customer : customerType, checkout) => {
    let payload = generatePayload("InitiateCheckout", customer, null);

    // Custom Data
    const contents = JSON.stringify(generateContentsFromCheckout(checkout));

    // Add purchase custom_data to payload
    payload = {
        ...payload,
        custom_data: {
            currency: "NZD",
            contents: contents
        }
    }

    axios.post("/.netlify/functions/send-event-to-meta-pixel", {
        payload: payload
    }).catch((e) => console.error(e));
}