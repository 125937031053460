import React, { createContext, useState, useMemo } from "react";

const defaultState = {
  isMiniCartOpen: false,
  upsellProducts: [],
  setUpsellProducts: (products) => { },
  addedFromUpsellProducts: [],
  setAddedFromUpsellProducts: (products) => { },
  miniCartWidth: 395,
  headerOffsetFromMinicart: 0,
};

export const MiniCartContext = createContext(defaultState);

export const MiniCartContextProvider = ({ children }) => {
  const [state, setState] = useState(defaultState);
  const [addedFromUpsellProducts, setAddedFromUpsellProducts] = useState([]);
  const [upsellProducts, setUpsellProducts] = useState([]);
  const [headerOffsetFromMinicart, setHeaderOffsetFromMinicart] = useState(0);

  const handleMiniCartVisibility = (isVisible) => {
    setState({ ...state, isMiniCartOpen: isVisible });

    let headerRightMargin = 0;
    if (isVisible) {
      headerRightMargin = state.miniCartWidth;
    }
    if (window.innerWidth < 800) {
      headerRightMargin = 0;
    }
    setHeaderOffsetFromMinicart(headerRightMargin);
  };

  const contextValue = useMemo(() => (
    {
      isMiniCartOpen: state.isMiniCartOpen,
      handleMiniCartVisibility,
      addedFromUpsellProducts,
      setAddedFromUpsellProducts,
      upsellProducts,
      setUpsellProducts,
      miniCartWidth: state.miniCartWidth,
      headerOffsetFromMinicart: headerOffsetFromMinicart,
    }
  ), [state, handleMiniCartVisibility, addedFromUpsellProducts, upsellProducts, headerOffsetFromMinicart])

  return (
    <MiniCartContext.Provider
      value={contextValue}
    >
      {children}
    </MiniCartContext.Provider>
  );
};
export default MiniCartContext;
