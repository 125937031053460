import React, { createContext, useContext, useState } from "react";
import User from "../../components/Icons/User";
import { useAuth } from "../auth/AuthContext";
import CustomerContext from "../customer/CustomerContext";

const defaultValues = {
  shopifyCustomer: null,
  shouldFetchDetails: true,
  hasWebsiteAccount: false,
};

export const ShopifyContext = createContext(defaultValues);

export const ShopifyContextProvider = ({ children }) => {
  const [state, setState] = useState(defaultValues);
  const { user, getSignInMethods } = useAuth();
  const { migrateShopifyCustomer } = useContext(CustomerContext);

  const migrateShopifyCustomerById = async (id) => {
    let signInMethods = null;

    const customer = await findShopifyCustomerById(id);

    if (customer?.email) {
      signInMethods = await getSignInMethods(customer.email);
      if (!user?.uid) {
        migrateShopifyCustomer(customer);
      }
    }
    setState({
      ...state,
      shopifyCustomer: customer,
      hasWebsiteAccount:
        signInMethods && signInMethods.length > 0 ? true : false,
      shouldFetchDetails: false,
    });
  };

  const findShopifyCustomerById = async (id) => {
    const response = await fetch(
      "/.netlify/functions/find-shopify-customer-by-id",
      {
        method: "POST",
        body: JSON.stringify({
          id,
        }),
      },
    );
    const { customer } = await response.json();
    return customer;
  };
  const findShopifyCustomerByEmail = async (email) => {
    const response = await fetch(
      "/.netlify/functions/find-shopify-customer-by-email",
      {
        method: "POST",
        body: JSON.stringify({
          email,
        }),
      },
    );
    const { customer } = await response.json();
    return customer;
  };

  const updateShopifyCustomerDetails = async (customer_id, customerDetails) => {
    const response = await fetch(
      "/.netlify/functions/update-shopify-customer-details",
      {
        method: "POST",
        body: JSON.stringify({
          customer_id,
          customerDetails,
        }),
      },
    );
    const { customerId, errorMessage } = await response.json();
    return errorMessage;
  };

  return (
    <ShopifyContext.Provider
      value={{
        shopifyCustomer: state.shopifyCustomer,
        hasWebsiteAccount: state.hasWebsiteAccount,
        shouldFetchDetails: state.shouldFetchDetails,
        findShopifyCustomerByEmail,
        migrateShopifyCustomerById,
        findShopifyCustomerById,
        updateShopifyCustomerDetails,
      }}
    >
      {children}
    </ShopifyContext.Provider>
  );
};

export function useShopify() {
  return useContext(ShopifyContext);
}
