import React, { createContext, useState, useEffect } from "react";
const defaultState = {
  windowWidth: 0,
  windowHeight: 0,
  stickyHeaderHeight: 0,
  headerHeight: 0,
  headerRightMargin: 0,
  mobileMenuOpen: false,
};

export const WindowContext = createContext(defaultState);

export const WindowContextProvider = ({ children }) => {
  const [state, setState] = useState(defaultState);

  const setStickyHeaderHeight = (height) => {
    setState((prevState) => ({ ...prevState, stickyHeaderHeight: height }));
  };

  const setHeaderHeight = (height) => {
    setState((prevState) => ({ ...prevState, headerHeight: height }));
  };

  const setHeaderRightMargin = (margin) => {
    setState((prevState) => ({ ...prevState, headerRightMargin: margin }));
  };

  const setMobileMenuOpen = (isOpen) => {
    setState((prevState) => ({ ...prevState, mobileMenuOpen: isOpen }));
  };

  useEffect(() => {
    const handleResize = () => {
      setState((prevState) => ({
        ...prevState,
        windowWidth: window.innerWidth,
        windowHeight: window.innerHeight,
      }));
      if (window.innerWidth > 800) {
        setMobileMenuOpen(false);
      }
    };

    if (typeof window === "undefined") return;

    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <WindowContext.Provider
      value={{
        windowWidth: state.windowWidth,
        windowHeight: state.windowHeight,
        stickyHeaderHeight: state.stickyHeaderHeight,
        headerHeight: state.headerHeight,
        headerRightMargin: state.headerRightMargin,
        mobileMenuOpen: state.mobileMenuOpen,
        setStickyHeaderHeight,
        setHeaderHeight,
        setMobileMenuOpen,
        setHeaderRightMargin,
      }}
    >
      {children}
    </WindowContext.Provider>
  );
};
export default WindowContext;
