exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-account-address-js": () => import("./../../../src/pages/account/address.js" /* webpackChunkName: "component---src-pages-account-address-js" */),
  "component---src-pages-account-autoship-js": () => import("./../../../src/pages/account/autoship.js" /* webpackChunkName: "component---src-pages-account-autoship-js" */),
  "component---src-pages-account-details-js": () => import("./../../../src/pages/account/details.js" /* webpackChunkName: "component---src-pages-account-details-js" */),
  "component---src-pages-account-index-js": () => import("./../../../src/pages/account/index.js" /* webpackChunkName: "component---src-pages-account-index-js" */),
  "component---src-pages-account-orders-js": () => import("./../../../src/pages/account/orders.js" /* webpackChunkName: "component---src-pages-account-orders-js" */),
  "component---src-pages-account-payment-js": () => import("./../../../src/pages/account/payment.js" /* webpackChunkName: "component---src-pages-account-payment-js" */),
  "component---src-pages-checkout-js": () => import("./../../../src/pages/checkout.js" /* webpackChunkName: "component---src-pages-checkout-js" */),
  "component---src-pages-complete-login-js": () => import("./../../../src/pages/completeLogin.js" /* webpackChunkName: "component---src-pages-complete-login-js" */),
  "component---src-pages-delivery-confirmed-js": () => import("./../../../src/pages/deliveryConfirmed.js" /* webpackChunkName: "component---src-pages-delivery-confirmed-js" */),
  "component---src-pages-forgot-js": () => import("./../../../src/pages/forgot.js" /* webpackChunkName: "component---src-pages-forgot-js" */),
  "component---src-pages-invoice-js": () => import("./../../../src/pages/invoice.js" /* webpackChunkName: "component---src-pages-invoice-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-newpassword-js": () => import("./../../../src/pages/newpassword.js" /* webpackChunkName: "component---src-pages-newpassword-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-order-confirm-js": () => import("./../../../src/pages/orderConfirm.js" /* webpackChunkName: "component---src-pages-order-confirm-js" */),
  "component---src-pages-products-js": () => import("./../../../src/pages/products.js" /* webpackChunkName: "component---src-pages-products-js" */),
  "component---src-pages-signup-js": () => import("./../../../src/pages/signup.js" /* webpackChunkName: "component---src-pages-signup-js" */),
  "component---src-pages-user-management-js": () => import("./../../../src/pages/userManagement.js" /* webpackChunkName: "component---src-pages-user-management-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-templates-product-js": () => import("./../../../src/templates/product.js" /* webpackChunkName: "component---src-templates-product-js" */)
}

