import React from "react";
import { createNewError } from "../repository/errors";
/**
 * Is value numeric
 * 
 * Determine whether variable is a number
 * 
 * @param {*} str 
 *
  import { isNumeric } from '../helpers/general'

  isNumeric(value)
*/
function isNumeric(str) {
  if (["string", "number"].indexOf(typeof str) === -1) return false; // we only process strings and numbers!
  return (
    !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
    !isNaN(parseFloat(str))
  ); // ...and ensure strings of whitespace fail
}

/**
 * Validate email format
 * 
 * Checks the provided email address and validates its format
 * 
 * @param   {String} email  The email address
 * 
    import { validateEmail } from '../helpers/general'

    validateEmail(email)
 */
function validateEmail(email) {
  const isValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email?.trim());

  if (!isValid) {
    createNewError({
      createdAt: new Date(),
      source: "validateEmail",
      payload: email,
      message: "Invalid Email",
    });
  }
  return isValid;
}

/**
 * Validate strong password format
 * 
 * 
 * @param   {String} password  The password
 * 
    import { validateStrongPassword } from '../helpers/general'

    validateStrongPassword(email)
 */
function validateStrongPassword(password) {
  return /(?=.{8,})(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/.test(password);
}

/**
 * Checks for empty string
 * 
 * @param   {String} email  input
 * 
    import { isEmpty } from '../helpers/general'

    isEmpty(email)
 */
function isEmpty(input) {
  if (input === "" || input === null || input === undefined || input === " ")
    return true;
}

/**
 * Checks if user is authenticated
 * 
 * 
 * 
    import { isAuth } from '../helpers/general'

    isAuth()
 */
function isAuth() {
  const isBrowser = typeof window !== "undefined";
  if (isBrowser) {
    const token = window.localStorage.getItem("key");
    if (token) return true;
    else return false;
  } else {
    return true;
  }
}

function capitalizeWord(word) {
  if (word && word.length > 1) {
    return word[0].toUpperCase() + word.substring(1).toLowerCase();
  }
  return word;
}

// Define function to check if email address has a typo and suggest correction
function checkEmailTypo(email) {
  const domain = email.split("@")[1];
  const parts = domain.split(".");
  const tlds = ["com", "co", "nz", "gov", "kiwi", "au"]; // Add more valid TLDs as needed
  const lastTld = parts[parts.length - 1];

  let isValid = true;
  let suggestedEmail = null;

  if (!tlds.includes(lastTld)) {
    isValid = false;
    suggestedEmail = email.replace(`.${lastTld}`, `.${tlds[0]}`);
    if (
      lastTld.includes("n") &&
      (lastTld.length == 2 || lastTld.includes("nz"))
    ) {
      suggestedEmail = email.replace(`.${lastTld}`, `.${tlds[2]}`);
    }
  }
  if (email.includes("gmail.co.nz")) {
    isValid = false;
    suggestedEmail = email.replace(`gmail.co.nz`, `gmail.com`);
  }
  if (suggestedEmail) {
    if (suggestedEmail.includes("gmail.nz")) {
      suggestedEmail = suggestedEmail.replace(`gmail.nz`, `gmail.com`);
    } else if (suggestedEmail.includes("gmail.co.nz")) {
      suggestedEmail = suggestedEmail.replace(`gmail.co.nz`, `gmail.com`);
    }
  }

  return {
    isValid: isValid,
    suggestedEmail: suggestedEmail,
  };
}

// export default function useWindowSize() {
//   if(window !== "undefined"){
//     const isSSR = typeof window !== "undefined";
//     const [windowSize, setWindowSize] = React.useState({
//       width: isSSR ? 1200 : window.innerWidth,
//       height: isSSR ? 800 : window.innerHeight,
//     });

//     function changeWindowSize() {
//       setWindowSize({ width: window.innerWidth, height: window.innerHeight });
//     }

//     React.useEffect(() => {
//       window.addEventListener("resize", changeWindowSize);

//       return () => {
//         window.removeEventListener("resize", changeWindowSize);
//       };
//     }, []);

//     return windowSize;
//   }
//   return null
// }

export {
  isNumeric,
  validateEmail,
  validateStrongPassword,
  isEmpty,
  isAuth,
  capitalizeWord,
  checkEmailTypo,
};
