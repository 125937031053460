import React from "react";

const User = () => (
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.0002 2.99992C12.4668 2.99992 13.6668 4.19992 13.6668 5.66658C13.6668 7.13325 12.4668 8.33325 11.0002 8.33325C9.5335 8.33325 8.3335 7.13325 8.3335 5.66658C8.3335 4.19992 9.5335 2.99992 11.0002 2.99992ZM11.0002 14.9999C14.6002 14.9999 18.7335 16.7199 19.0002 17.6666V18.9999H3.00016V17.6799C3.26683 16.7199 7.40016 14.9999 11.0002 14.9999ZM11.0002 0.333252C8.0535 0.333252 5.66683 2.71992 5.66683 5.66658C5.66683 8.61325 8.0535 10.9999 11.0002 10.9999C13.9468 10.9999 16.3335 8.61325 16.3335 5.66658C16.3335 2.71992 13.9468 0.333252 11.0002 0.333252ZM11.0002 12.3333C7.44016 12.3333 0.333496 14.1199 0.333496 17.6666V21.6666H21.6668V17.6666C21.6668 14.1199 14.5602 12.3333 11.0002 12.3333Z"
      fill="black"
    />
  </svg>
);

export default User;
