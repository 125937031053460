const { createClient } = require("@sanity/client");

const sanityConfig = {
  projectId: "edmt2lyr",
  dataset: "production",
  token: process.env.SANITY_API_TOKEN,
  apiVersion: "2023-11-24",
  useCdn: false,
};
const sanity = createClient(sanityConfig);
module.exports = { sanity };
