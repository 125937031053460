import axios from "axios";
import {
  checkSkuForCapsulesTotal,
  convertIdFromGraphql,
} from "./shopify/converter";
import { PhoneNumberUtil, PhoneNumberFormat } from "google-libphonenumber";
import { pushPixelAddVariantToCart } from "./pixelDataTrackingManagement";

export const pushVariantTrackingEvent = (
  typeUA,
  typeGA4,
  variant,
  quantity,
  checkout,
  customer,
) => {
  if (typeof window !== "undefined") {
    // create a new datalyer, or let it be empty
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({ ecommerce: null });
    // UA add to cart -
    window.dataLayer.push({
      event: typeUA,
      ecommerce: {
        currencyCode: "NZD",
        add: {
          // 'add' actionFieldObject measures.
          products: [
            {
              name: variant.productTitle || variant.title,
              variant: variant.title, // Replace with product size
              id: variant.sku, // Replace with SKU
              price: variant.price, // Replace with item price
              brand: "CC2U",
              quantity: quantity, // Replace with item quantity added to cart
            },
          ],
        },
      },
    });
    pushGA4VariantTrackingEvent(typeGA4, variant, quantity);
    if (typeUA !== "removeFromCart") {
      pushKlaviyoVariantTrackingEvent(variant, quantity, checkout);
      pushPixelAddVariantToCart(variant.sku, 1, customer);
    }
  }
};

export const pushGA4VariantTrackingEvent = (type, variant, quantity) => {
  if (typeof window !== "undefined") {
    // create a new datalyer, or let it be empty
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push({
      event: type,
      ecommerce: {
        items: [
          {
            item_id: variant.sku,
            item_name: variant.productTitle || variant.title,
            item_variant: variant.title,
            affiliation: "CC2U",
            currency: "NZD",
            item_brand: "CC2U",
            price: variant.price,
            quantity: quantity,
          },
        ],
      },
    });
  }
};

export const pushGA4ViewCartTrackingEvent = (cartItems) => {
  if (typeof window !== "undefined") {
    // create a new datalyer, or let it be empty
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({ ecommerce: null });
    const items = cartItems.map((item) => ({
      item_id: item.variant?.sku,
      item_name: item.title,
      item_variant: item.variant?.title,
      affiliation: "CC2U",
      currency: "NZD",
      item_brand: "CC2U",
      price: Number(item.variant?.priceV2.amount) * item.quantity,
      quantity: item.quantity,
    }));
    window.dataLayer.push({
      event: "view_cart",
      ecommerce: {
        currency: "NZD",
        value: Number(
          items.reduce((a, b) => Number(a.price) + Number(b.price)),
        ).toFixed(2),
        items: items,
      },
    });
  }
};

export const pushGA4CheckoutTrackingEvent = (type, cartItems) => {
  if (typeof window !== "undefined") {
    // create a new datalyer, or let it be empty
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({ ecommerce: null });
    const items = cartItems.map((item) => ({
      item_id: item.variant.sku,
      item_name: item.title,
      item_variant: item.variant.title,
      affiliation: "CC2U",
      currency: "NZD",
      item_brand: "CC2U",
      price: Number(item.variant?.priceV2.amount) * item.quantity,
      quantity: item.quantity,
    }));
    window.dataLayer.push({
      event: type,
      ecommerce: {
        items: items,
      },
    });
  }
};

export const pushCompleteCheckoutTrackingEvent = (
  transactionId,
  order,
  shippingCost,
  lineItems,
) => {
  if (typeof window !== "undefined") {
    // create a new datalyer, or let it be empty
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({ ecommerce: null });
    const items = lineItems.map((item) => ({
      name: item.title,
      variant: item.variant_title,
      id: item.sku,
      brand: "CC2U",
      price: Number(item.price)?.toFixed(2),
      quantity: item.quantity,
    }));
    window.dataLayer.push({
      event: "ua_purchase",
      ecommerce: {
        purchase: {
          actionField: {
            id: transactionId,
            affiliation: "CC2U",
            revenue: Number(order.totalAmount),
            shipping: shippingCost?.rate || 0,
          },
          products: items,
        },
      },
    });

    pushGA4CompleteCheckoutTrackingEvent(
      transactionId,
      order,
      shippingCost,
      lineItems,
    );
  }
};

export const pushGA4CompleteCheckoutTrackingEvent = (
  transactionId,
  order,
  shippingCost,
  lineItems,
) => {
  if (typeof window !== "undefined") {
    // create a new datalyer, or let it be empty
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({ ecommerce: null });
    const items = lineItems.map((item) => ({
      item_id: item.sku,
      item_name: item.title,
      item_variant: item.variant_title,
      affiliation: "CC2U",
      currency: "NZD",
      item_brand: "CC2U",
      price: Number(Number(item.price)?.toFixed(2)),
      quantity: item.quantity,
    }));

    window.dataLayer.push({
      event: "purchase",
      ecommerce: {
        id: transactionId,
        affiliation: "CC2U",
        currency: "NZD",
        coupon: order.appliedDiscount ? order.appliedDiscount?.title : "",
        discount: order.appliedDiscount
          ? Number(Number(order.appliedDiscount?.amount)?.toFixed(2))
          : 0,
        value: Number(order.totalAmount),
        shipping: shippingCost?.rate || 0,
        items: items,
      },
    });
  }
};

export const pushGtagEnhanchedConversionEvent = (
  transactionId,
  orderTotal,
  email,
  phone_number,
  shippingAddress,
) => {
  if (typeof window !== "undefined" && typeof window.gtag !== "undefined") {
    window.gtag("set", "user_data", {
      email: email,

      phone_number: phone_number,

      address: {
        first_name: shippingAddress.firstName,

        last_name: shippingAddress.lastName,

        street:
          shippingAddress.addressLine1 + ", " + shippingAddress.addressLine2,

        city: shippingAddress.city,

        region: shippingAddress.city,

        postal_code: shippingAddress.postCode,

        country: "NZ",
      },
    });

    window.gtag("event", "conversion", {
      send_to: "AW-866895883/-PfcCOPn8qIYEIuQr50D",
      value: Number(orderTotal),
      currency: "NZD",
      transaction_id: `${transactionId}`,
    });
  }
};

export const pushKlaviyoIdentity = (customer) => {
  if (typeof window !== "undefined") {
    window._learnq = window._learnq || [];
    window._learnq.push([
      "identify",
      {
        $email: customer.email,
        $first_name: customer.firstName,
        $last_name: customer.lastName,
        $phone_number: customer.phoneNumber,
      },
    ]);
  }
};

export const setSMSSubscription = (email, phoneNumber, shouldSubscribe) => {
  axios.post("/.netlify/functions/set-sms-consent-klaviyo", {
    email: email,
    phone_number: phoneNumber,
    sms_consent: shouldSubscribe ? true : false,
  });
};

export const getSMSConsent = async (email, phoneNumber) => {
  const response = await axios.post(
    "/.netlify/functions/get-sms-consent-klaviyo",
    {
      email: email,
      phone_number: phoneNumber,
    },
  );

  return response?.data?.hasConsent;
};

export const pushKlaviyoVariantTrackingEvent = (
  variant,
  quantity,
  checkout,
) => {
  if (typeof window !== "undefined") {
    window._learnq = window._learnq || [];
    window._learnq.push([
      "track",
      "Added to Cart",
      {
        $value: Number(checkout?.totalPrice),
        AddedItemProductName: variant?.productTitle + " - " + variant?.title,
        AddedItemProductID: variant?.shopifyId,
        AddedItemSKU: variant?.sku,
        AddedItemImageURL: variant?.mainImageSrc,
        AddedItemQuantity: quantity,
        ItemNames: checkout?.lineItems?.map(
          (i) => i.title + " - " + i?.variant?.title,
        ),
        CheckoutURL:
          "https://coffeecapsules2u.co.nz/checkout?vid=" +
          checkout?.lineItems?.map(
            (i) =>
              convertIdFromGraphql(i.variant?.id) +
              ":" +
              i?.quantity +
              ":" +
              checkSkuForCapsulesTotal(i?.variant.sku, 1),
          ),
        Items: checkout?.lineItems?.map((i) => ({
          ProductID: convertIdFromGraphql(i.variant?.id),
          SKU: i.variant?.sku,
          ProductName: i.title + " - " + i?.variant?.title,
          Quantity: i.quantity,
          ItemPrice: i.variant?.price,
          RowTotal: Number(i.variant?.price) * i.quantity,
          ImageURL: i.variant?.image?.src,
        })),
      },
    ]);
  }
};

export const pushKlaviyoViewProductTackingEvent = (product) => {
  if (typeof window !== "undefined") {
    window._learnq = window._learnq || [];
    window._learnq.push([
      "track",
      "Viewed Product",
      {
        ProductName: product?.title,
        ProductID: product?.shopifyId,
        ImageURL: product?.mainImageSrc,
        URL: "https://coffeecapsules2u.co.nz/products/" + product?.slug,
      },
    ]);
    window._learnq.push([
      "trackViewedItem",
      {
        Title: product?.title,
        ItemId: product?.shopifyId,
        ImageUrl: product?.mainImageSrc,
        Url: "https://coffeecapsules2u.co.nz/products/" + product?.slug,
      },
    ]);
  }
};

export const pushKlaivyioStartedCheckout = (checkout, email) => {
  if (typeof window !== "undefined") {
    window._learnq = window._learnq || [];
    window._learnq.push([
      "track",
      "Started Checkout",
      {
        $value: Number(checkout.totalPrice),
        ItemNames: checkout?.lineItems?.map(
          (i) => i.title + " - " + i?.variant?.title,
        ),
        CheckoutURL:
          "https://coffeecapsules2u.co.nz/checkout?vid=" +
          checkout?.lineItems?.map(
            (i) =>
              convertIdFromGraphql(i.variant?.id) +
              ":" +
              i?.quantity +
              ":" +
              checkSkuForCapsulesTotal(i?.variant.sku, 1),
          ) +
          "&email=" +
          email,
        Items: checkout?.lineItems?.map((i) => ({
          ProductID: convertIdFromGraphql(i.variant?.id),
          SKU: i.variant?.sku,
          ProductName: i.title + " - " + i?.variant?.title,
          Quantity: i.quantity,
          ItemPrice: i.variant?.price,
          RowTotal: Number(i.variant?.price) * i.quantity,
          ImageURL: i.variant?.image?.src,
        })),
      },
    ]);
  }
};
