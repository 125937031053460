import { getApps, initializeApp } from "firebase/app";
import { getFirestore, connectFirestoreEmulator, collection, getDocs } from "firebase/firestore";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: process.env.GATSBY_FIREBASE_API_KEY,
  projectId: process.env.GATSBY_FIREBASE_PROJECT_ID,
  appId: process.env.GATSBY_FIREBASE_APP_ID,
  authDomain: process.env.GATSBY_FIREBASE_AUTH_DOMAIN,
};
const app = initializeApp(firebaseConfig);

if (!getApps.length) {
  if (typeof window !== "undefined") {
    // if ("measurementId" in firebaseConfig) {
    //    getAnalytics()
    // }
  }
}
const firestore = getFirestore(app);
// if (process.env.GATSBY_EMULATE_FIRESTORE === "true") {
//   console.log("Emulating firestore");
//   connectFirestoreEmulator(firestore, "localhost", 8080);
// }

const auth = getAuth(app);
export { firestore, auth };
