import { firestore } from "../config/firebase";
import { doc, collection, setDoc } from "firebase/firestore";

const createNewError = async (error) => {
  try {
    const newDocRef = doc(collection(firestore, "errors"));
    error.id = newDocRef.id;
    await setDoc(newDocRef, error);
  } catch (err) {
    console.error(err);
    console.error("createNewError");
    console.error(error);
    console.error(JSON.stringify(error));
  }

  return error;
};

export { createNewError };
