import React, { createContext, useContext, useEffect, useState } from "react";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  onAuthStateChanged,
  signOut,
  sendPasswordResetEmail,
  fetchSignInMethodsForEmail,
  signInWithPhoneNumber,
  linkWithPhoneNumber,
} from "firebase/auth";
import { auth } from "../../config/firebase";
import { setWasLoggedIn, getWasLoggedIn } from "../../helpers/localStorage";
import * as Sentry from "@sentry/gatsby";

export const AuthContext = createContext({ user: {} });

export const AuthContextProvider = ({ children }) => {
  const [user, setUser] = useState({});
  const [authLoaded, setAuthLoaded] = useState(false);
  const [wasLoggedIn, _] = useState(getWasLoggedIn());

  const login = (email, password) => {
    return signInWithEmailAndPassword(auth, email, password);
  };
  const signup = (email, password) => {
    return createUserWithEmailAndPassword(auth, email, password);
  };
  const logout = () => {
    return signOut(auth);
  };
  const resetPasswordByEmail = (email) => {
    return sendPasswordResetEmail(auth, email);
  };
  const getSignInMethods = (email) => {
    return fetchSignInMethodsForEmail(auth, email);
  };
  const loginWithPhoneNumber = (phone, appVerifier) => {
    return signInWithPhoneNumber(auth, phone, appVerifier);
  };
  const linkAccountWithPhoneNumber = (phone, appVerifier) => {
    return linkWithPhoneNumber(user, phone, appVerifier);
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentuser) => {
      // Main user object
      setUser(currentuser);
      // Login status
      setAuthLoaded(true);

      // Extras
      try {
      Sentry.setUser({ email: currentuser?.email });
      setWasLoggedIn(currentuser ? true : false);
      } catch {}
    });

    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <AuthContext.Provider
      value={{
        user,
        authLoaded,
        wasLoggedIn,
        login,
        signup,
        logout,
        getSignInMethods,
        resetPasswordByEmail,
        loginWithPhoneNumber,
        linkAccountWithPhoneNumber,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export function useAuth() {
  return useContext(AuthContext);
}
